import React from 'react'
import { reservationTimerContext } from './'
import { Props } from './types'
import { useReservationTimerProvider } from './useReservationTimerProvider'

export const ReservationTimerProvider = ({ children }: Props) => {
  const {
    getSecondsToExpiration,
    hasReservationTimerExpired,
    initTimer,
    resetTimer,
    showModalReservationExpired,
    stopTimer,
    stopTimerOnErrorReservation,
    hasReservationTimerError,
    titleReservationTimer
  } = useReservationTimerProvider()

  return (
    <reservationTimerContext.Provider
      value={{
        titleReservationTimer,
        hasReservationTimerError,
        getSecondsToExpiration,
        hasReservationTimerExpired,
        initTimer,
        resetTimer,
        showModalReservationExpired,
        stopTimer,
        stopTimerOnErrorReservation
      }}
    >
      {children}
    </reservationTimerContext.Provider>
  )
}
