import { useCallback, useEffect, useMemo } from 'react'
import { Routes } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { settings } from './settings'

export const useSentryInit = () => {
  const initSentry = useCallback(() => {
    Sentry.init(settings.sentryInitOptions)
  }, [])

  const SentryRoutes = useMemo(() => {
    return Sentry.withSentryReactRouterV6Routing(Routes)
  }, [])

  useEffect(() => {
    initSentry()
  }, [])

  return {
    initSentry,
    SentryRoutes
  }
}
