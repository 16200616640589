import styled, { css } from 'styled-components'
import { TextBaseProps } from './interfaces'

export const cssBase = css<TextBaseProps>`
  ${({ flexVerticalAlign }) =>
    flexVerticalAlign && `align-items: ${flexVerticalAlign}`};
  ${({ flexHorizontalAlign }) =>
    flexHorizontalAlign && `justify-content: ${flexHorizontalAlign}`};
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform}`};
  ${({ color }) => (color ? `color: ${color}` : 'color: #004186')};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px`};
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}px`};
  ${({ border }) => border && `border: ${border}`};
  ${({ borderRight }) => borderRight && `border-right: ${borderRight}`};
  ${({ borderLeft }) => borderLeft && `border-left: ${borderLeft}`};
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom}`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}% !important`};
  ${({ width }) => width && `width: ${width}%`};
  ${({ display }) => display && `display: ${display}`};
  ${({ padding }) => padding && `padding: ${padding}`};
  margin: ${props => (props.margin ? props.margin : '')};
  ${({ bold }) => bold && `font-weight: bold`};
  ${({ flex }) => flex && `display: flex`};
  ${({ fontSize }) =>
    fontSize ? `font-size: ${fontSize}px` : 'font-size: 14px'};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  ${({ float }) => float && `float: ${float}`};
  ${({ hideLarge }) =>
    hideLarge &&
    `
@media screen and (min-width: 62rem) {
  display: none;
}
`};
  ${({ hideSmall }) =>
    hideSmall &&
    `
@media screen and (max-width: 62rem) {
  display: none;
}
`};
  ${({ colorHover }) =>
    colorHover &&
    `
&:hover {
  color: ${colorHover};
}
`};
  @media screen and (max-width: 62rem) {
    margin: ${props => props.marginSmall || ''};
    ${({ smallTextAlign }) =>
      smallTextAlign && `text-align: ${smallTextAlign}`};

    ${({ widthSmall }) => widthSmall && `width: ${widthSmall}`};
    ${({ paddingSmall }) => paddingSmall && `padding: ${paddingSmall}`};
    ${({ fontSizeSmall }) => fontSizeSmall && `font-size: ${fontSizeSmall}px`};
    ${({ fontWeightSmall }) =>
      fontWeightSmall && `font-weight: ${fontWeightSmall}`};
  }
  ${({ wordBreak }) =>
    wordBreak &&
    `
      word-break: ${wordBreak}
    `};
`

export const TextBaseStyled = styled.span<TextBaseProps>`
  ${cssBase}
`

export const TextButtonStyled = styled.a<TextBaseProps>`
  outline: none;
  &:hover {
    color: #feca30;
  }
  ${cssBase}
`
