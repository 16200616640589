import { v4 as uuidv4 } from 'uuid'
import { Usuario } from '../../models/Usuario'
import { store } from '../../store'
import { sessionKeys } from 'shared/consts/sessionKeys'
import ArmazenamentoHelper from 'utils/helpers/armazenamentoHelper'

export const obtemUsuarioLogado = (): Usuario | undefined => {
  const { usuarios } = store.getState().acessoLogado

  return usuarios?.logado
}

export const obtemUsuarioTitular = (): Usuario | undefined => {
  const { usuarios } = store.getState().acessoLogado

  return usuarios?.titular
}

export const obtemUsuariosDependentes = (): Usuario[] => {
  const { usuarios } = store.getState().acessoLogado

  return usuarios?.dependentes ?? []
}

export const obtemTodosOsUsuarios = (): Usuario[] => {
  const { usuarios } = store.getState().acessoLogado

  if (usuarios) {
    if (usuarios.titular) {
      return [usuarios.titular, ...usuarios.dependentes]
    }

    return usuarios.dependentes
  }

  return []
}

export const obtemUsuarioLogadoPorCpf = (cpf: string): Usuario | undefined => {
  const { usuarios } = store.getState().acessoLogado

  return usuarios?.logado.cpf === cpf ? usuarios.logado : undefined
}

export const obtemUsuarioPorCpf = (cpf: string): Usuario | undefined => {
  const { usuarios } = store.getState().acessoLogado

  let usuario = obtemUsuarioLogadoPorCpf(cpf)

  if (!usuario && usuarios?.titular) {
    usuario = usuarios.titular.cpf === cpf ? usuarios.titular : undefined
  }

  if (!usuario) {
    usuario = usuarios?.dependentes.filter(user => user.cpf === cpf)[0]
  }

  return usuario
}

export const isUsuarioLogadoTitular = (cpf: string): boolean => {
  const usuarioLogado = obtemUsuarioLogadoPorCpf(cpf)
  let isTitular = false

  if (usuarioLogado) {
    isTitular = usuarioLogado.titular
  }

  return isTitular
}

export const obtemApelidoUsuario = (): string => {
  const usuario = obtemUsuarioLogado()
  const apelido = usuario?.apelido ?? usuario?.nome ?? ''

  return apelido
}

export const setSessionUuId = () => {
  let sessionUuId = ArmazenamentoHelper.recuperaDoSessionStorage(
    sessionKeys.USER_UUID
  )
  if (sessionUuId) return sessionUuId

  sessionUuId = uuidv4()

  ArmazenamentoHelper.armazenaNoSessionStorage(
    sessionKeys.USER_UUID,
    sessionUuId
  )
  return sessionUuId
}

export const getSessionId = () => {
  const {
    acessoLogado: { ativo: isLoggedIn, usuarios }
  } = store.getState()

  let sessionUuId = ArmazenamentoHelper.recuperaDoSessionStorage(
    sessionKeys.USER_UUID
  )
  if (!sessionUuId) {
    sessionUuId = setSessionUuId()
  }

  const userId = isLoggedIn ? usuarios?.logado?.id : 'anonymous'

  return `${userId}-${sessionUuId}`
}
