import React from 'react'
import { CuraIcon } from '@rededor/cura-react'

import { Button, Label, WrapperIcon } from './styles'
import { ButtonChatDefaultProps } from './types'
import useController from './controller'
import { Breakpoint } from 'shared/Breakpoint'
import useMatchMedia from 'shared/Hooks/useMatchMedia'
import MixpanelHelper from 'utils/helpers/mixpanelHelper'
import { AbrirAtendimentoViaChat } from 'models/MixPanel'
import MixPanelEventos from 'shared/consts/mixpanel/eventos'

export const ButtonChatDefault = ({
  animate = false,
  visible = true,
  dataQaId
}: ButtonChatDefaultProps) => {
  const { match: isMobile } = useMatchMedia(
    `(max-width: ${Breakpoint.SmallDesktop})`
  )
  const { offset } = useController()

  return (
    <>
      <Button
        dataQaId={dataQaId}
        animate={animate}
        visible={visible}
        id="webchat"
        onClick={() => {
          window?._handleOpenNice()

          MixpanelHelper.sendEventGeneric<AbrirAtendimentoViaChat>(
            MixPanelEventos.ABRIR_ATENDIMENTO_CHAT,
            {
              PAGINA_ORIGEM: document.title
            }
          )
        }}
        width="auto"
        big
        offsetBottom={offset}
      >
        <WrapperIcon>
          <CuraIcon
            name={isMobile ? 'questionCircle' : 'chatCircle'}
            color="neutral-white"
            size={isMobile ? 40 : 32}
          />
        </WrapperIcon>
        <Label>Preciso de Ajuda</Label>
      </Button>
    </>
  )
}
