import axios, { GenericAbortSignal } from 'axios'
import RequisicaoAdapter from '../adapters/RequisicaoAdapter'
import endpoints from '../shared/consts/endpoints'
import { Horarios } from '../models/Horario'
import { ListaOfertasBackend } from '../models/HorarioOferta'
import { PrecoRequestResponse } from '../models/Preco'
import { converteDiaOfertaEmHorarios } from '../utils/helpers/horariosHelper'

class OfertaService {
  static async obtemAgenda(
    modalidade: 'especialidade' | 'medico',
    parametros: any,
    chaveDeCancelamento?: GenericAbortSignal
  ): Promise<Horarios> {
    const url = endpoints.oferta.agenda[modalidade]

    try {
      const response = await RequisicaoAdapter.request<ListaOfertasBackend>({
        url,
        signal: chaveDeCancelamento,
        params: parametros
      })

      return converteDiaOfertaEmHorarios(response.data.dias || [])
    } catch (error) {
      if (axios.isCancel(error)) {
        return converteDiaOfertaEmHorarios([])
      }
      throw new Error('Erro ao obter ofertas de horário')
    }
  }

  static async obtemValorDaConsulta(
    parametros: any
  ): Promise<PrecoRequestResponse> {
    const url = endpoints.oferta.valorDaConsulta

    return await RequisicaoAdapter.request<PrecoRequestResponse>({
      url,
      params: parametros
    }).then(response => {
      return response.data
    })
  }
}

export default OfertaService
