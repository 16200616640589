const MixPanelEventos = {
  ABRIR_ATENDIMENTO_CHAT: 'Abrir Atendimento via Chat',
  ACESSAR_ESPERA: 'Acessar sala de espera de teleconsulta',
  ACESSAR_TELECONSULTA: 'Entrar sala de teleconsulta',
  ADICIONAR_AGENDA: 'Adicionar à Agenda',
  EXIBIR_ERRO_BACK_END: 'Exibir Erro Generico backend',
  EXIBIR_ERRO: 'Exibir Erro Generico',
  ATIVAR_TOGGLE_GEO_LOCALIZACAO: 'Ativar o uso da Geo',
  BLOQUEAR_GEO_LOCALIZACAO: 'Negar localizacao',
  CANCELAR_AGENDAMENTO: 'Cancelar Agendamento',
  CLICOU_NA_SUGESTAO_LOCAL: 'Selecionar sugestoes',
  CONSULTAR_PRECO_CONSULTA: 'Consultar Preco da Consulta',
  CTA: 'CTA',
  CTA_FALLBACK: 'CTA Fallback',
  DESATIVAR_TOGGLE_GEO_LOCALIZACAO: 'Desativar o uso da Geo',
  EDITAR_ESPECIALIDADE: 'Editar Especialidade',
  EDITAR_LOCAL: 'Editar Local',
  EDITAR_PACIENTE: 'Editar Paciente',
  EDITAR_PAGAMENTO: 'Editar Pagamento',
  ESCOLHA_LOCAL: 'Escolher Local',
  ESCOLHA_MEDICO_ESPECIALIDADE: 'Escolher Especialidade e/ou Medico',
  ESCOLHER_FORMA_PAGAMENTO: 'Escolher Forma de Pagamento',
  ESCOLHER_HORARIOS: 'Escolher Horarios',
  FALLBACK: 'Fallback',
  ESCOLHER_OUTRA_ESPECIALIDADE_FILTRO: 'Escolher Outra Especialidade - Filtro',
  ESCOLHER_UNIDADE_ETAPA_FILTRO: 'Escolher Unidade pela Etapa de Filtro',
  EXIBIR_ERRO_ELEGIBILIDADE: 'Exibir Erro de Elegibilidade',
  EXIBIR_TELA_SUCESSO: 'Visualizar página de sucesso',
  FILTRAR_HORARIOS: 'Filtrar Horarios',
  FINALIZAR_CADASTRO: 'Finalizar Cadastro',
  GEO_LOCALIZACAO_MODAL_FOI_EXIBIDO: 'Exibir instrucoes para ativar Geo',
  INFORMAR_DADOS_PESSOAIS: 'Informar Dados Pessoais',
  INTERACT_WITH_APP: 'Interagir com aplicacao',
  LISTAR_HORARIOS: 'Listar Horarios',
  LISTAR_SUGESTAO_LOCAL: 'Visualizar página de sugestões de unidades próximas',
  MARCAR_DESMARCAR_FILTRO: 'Marcar e Desmarcar - Filtro',
  PERMITIR_GEO_LOCALIZACAO: 'Permitir localizacao',
  PROSSEGUIR_FILTRO: 'Prosseguir - Filtro',
  RETORNO_LOGIN: 'POC Retorno Login',
  SELECIONAR_SUGESTAO_CONVENIO: 'Selecionar Sugestao de Convenio',
  SELECIONAR_SUGESTAO_PLANO: 'Selecionar Sugestao de Plano',
  TENTATIVA_LOGIN: 'POC Tentativa Login',
  VERIFICAR_COBERTURA: 'Verificar Cobertura',
  VERIFICAR_ATENDIMENTO: 'Verificar Atendimento',
  VISUALIZAR_UNIDADE_ETAPA_FILTRO: 'Visualizar Unidade pela Etapa de Filtro',
  VISUALIZAR_AREA_LOGADA: 'Visualizar Area logada',
  VISUALIZAR_TELA_CONFIRMACAO_DADOS: 'Visualizar tela de confirmação de dados',
  CONFIRMAR_AGENDAMENTO: 'Confirmar agendamento',
  VISUALIZAR_TELA_HISTORICO: 'Visualizar tela histórico',
  INTERAGIR_MENU: 'Interagir com menu',
  CANCELAR_CONSULTA: 'Cancelar Consulta',
  VISUALIZAR_TELA_CANCELAMENTO: 'Visualizar tela de cancelamento',
  VISUALIZAR_TELA_CANCELAMENTO_CONFIRMADO:
    'Visualizar tela de cancelamento confirmado',
  VISUALIZAR_TELA_DADOS_PACIENTE: 'Visualizar tela de cadastro no início',
  VISUALIZAR_TELA_CADASTRO_FINAL: 'Visualizar tela de cadastro no final',
  SESSION_START: 'Session start',
  SESSION_END: 'Session end',
  DROPDOWN_ESPECIALIDADES: 'Dropdown ver mais especialidades',
  CADASTRAR_PACIENTE_INICIO: 'Cadastrar paciente no inicio',
  VISUALIZAR_PAGINA_ESPECIALIDADE_MEDICO: 'Visualizar página de especialidade'
}

export default MixPanelEventos
