import { AcessoLogado } from '../../models/AcessoLogado'

export const LOGAR_USUARIO = 'LOGAR_USUARIO'
export const DESLOGAR_USUARIO = 'DESLOGAR_USUARIO'
export const SELECIONAR_USUARIO = 'SELECIONAR_USUARIO'

export interface AcessoLogadoAction {
  type: string
  payload: AcessoLogado
}

export const acessoLogadoInitialState: AcessoLogado = {
  ativo: false,
  usuarios: undefined,
  usuarioSelecionadoParaAgendamento: undefined,
  etapaDeEscolhaDeUsuarioConcluida: false
}
