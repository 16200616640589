import { differenceInYears, isAfter, isValid, parse } from 'date-fns'

export const useInputBirthDate = () => {
  const checkIsValidAge = (parsedDate: Date) => {
    if (isAfter(parsedDate, new Date())) {
      return {
        valid: false,
        message: 'Data deve ser menor ou igual à hoje'
      }
    }

    const age = differenceInYears(new Date(), parsedDate)
    if (age > 150) {
      return {
        valid: false,
        message: 'Idade deve estar entre 0 e 150 anos'
      }
    }

    return {
      valid: true,
      message: ''
    }
  }

  const checkIsValidDate = (date: string) => {
    if (date.replace(/\D/g, '').length < 8) {
      return {
        valid: false,
        message: 'Data inválida'
      }
    }

    const parsedDate: Date = parse(date, 'dd/MM/yyyy', new Date())

    if (!isValid(parsedDate)) {
      return {
        valid: false,
        message: 'Data de nascimento inválida'
      }
    }

    const { valid: isValidAge, message } = checkIsValidAge(parsedDate)

    if (!isValidAge) {
      return {
        valid: false,
        message
      }
    }

    return {
      valid: true,
      message: ''
    }
  }

  return { checkIsValidDate }
}
