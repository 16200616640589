import { useCallback, useEffect, useState } from 'react'
import { Breakpoint } from '../../shared/Breakpoint'
import useCookieBannerStatus from '../../shared/Hooks/useCookieBannerStatus'
import useMatchMedia from '../../shared/Hooks/useMatchMedia'
const STEPBAR_HEIGHT = 58

const useController = () => {
  const { match: isMobile } = useMatchMedia(
    `(max-width: ${Breakpoint.SmallDesktop})`
  )
  const { cookieBannerState } = useCookieBannerStatus()
  const [offset, setOffset] = useState(0)

  const getButtonOffset = useCallback(() => {
    if (cookieBannerState) {
      const { height: bannerHeight } =
        cookieBannerState.el.getBoundingClientRect()
      const buttonOffset = isMobile
        ? bannerHeight + STEPBAR_HEIGHT
        : bannerHeight

      return Math.ceil(buttonOffset) || 0
    }
  }, [cookieBannerState])

  const calculateOffset = useCallback(() => {
    const OFFSET_MAP = new Map([
      ['original', { mobile: getButtonOffset(), desktop: getButtonOffset() }],
      ['minified', { mobile: getButtonOffset(), desktop: 0 }],
      ['invisible', { mobile: STEPBAR_HEIGHT, desktop: 0 }]
    ])

    const offset = isMobile
      ? OFFSET_MAP.get(cookieBannerState?.state || 'original')?.mobile
      : OFFSET_MAP.get(cookieBannerState?.state || 'original')?.desktop

    setOffset(offset || 0)
  }, [cookieBannerState, isMobile, getButtonOffset])

  useEffect(() => {
    calculateOffset()
  }, [cookieBannerState, calculateOffset, isMobile])

  return { offset }
}

export default useController
