import api from './api'

const endpoints = {
  aplicacao: {
    emManutencao: `${api.agendamento}/api/v1/manutencao`
  },
  oferta: {
    agenda: {
      medico: `${api.agendamento}/api/v1/oferta/medico`,
      especialidade: `${api.agendamento}/api/v1/oferta/especialidade`
    },
    valorDaConsulta: `${api.agendamento}/api/v1/oferta/preco`
  },
  unidade: {
    sugestao: {
      qualquerPagamento: `${api.agendamento}/api/v1/unidade/especialidade/busca-fora-da-area`,
      pagamentoComPlano: `${api.agendamento}/api/v1/unidade/especialidade/plano/busca-fora-da-area`,
      byRadius: `${api.agendamento}/api/v1/unidade/expandir-por-raio`
    }
  },
  usuario: {
    informacoes: {
      viaToken: `${api.areaDoPaciente}/agendamento-consulta/interno/pacientes`
    }
  },
  requisicao: {
    novoCodigo: `${api.agendamento}/api/v1/requisicao`,
    tradutor: `${api.agendamento}/api/v1/tradutor/cancelamento`
  },
  agendamento: {
    confirma: {
      usuarioLogado: `${api.agendamento}/api/v1/interno/agendamento/confirma`,
      usuarioDeslogado: `${api.agendamento}/api/v1/agendamento/confirma`
    },
    reserva: `${api.agendamento}/api/v2/schedules`,
    cancela: `${api.agendamento}/api/v1/agendamento/cancela`,
    cancelamento: `${process.env.PUBLIC_URL}/cancelar-consulta/`,
    teleconsulta: {
      sala: {
        informacoes: `${api.agendamento}/api/v1/consulta-online/sala`,
        comparecimento: `${api.agendamento}/api/v1/consulta-online/comparecimento`
      }
    },
    informacoes: {
      viaHash: `${api.agendamento}/api/v1/tradutor/cancelamento`
    },
    historico: `${api.agendamento}/api/v1/historico/consultas`,
    resendToken: `${api.agendamento}/api/v1/agendamento/resend-token`
  },
  preenchimento: {
    verificaElegibilidade: `${api.agendamento}/api/v1/elegibilidade`,
    informacoes: {
      preenchimentoParametrizado: `${api.agendamento}/api/v1/tradutor/site`,
      patientDataSession: `${api.agendamento}/api/v1/pacientes/sessao`,
      patientDataEvent: `${api.agendamento}/api/v1/pacientes/evento`
    }
  },
  wordpress: {
    banners: `${api.wordpress}/banners`
  }
}

export default Object.freeze(endpoints)
