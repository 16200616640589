import {
  ARMAZENAR_ERRO_GENERICO,
  CLEAR_STORED_ERROR,
  SET_ERROR_BAD_GATEWAY
} from './types'
import { ErrorConsulta } from '../../models/ErrorConsulta'

const initialState: ErrorConsulta = {
  errors: undefined,
  errorBadGateway: false
}

export function errorReducer(state = initialState, action: any): ErrorConsulta {
  switch (action.type) {
    case ARMAZENAR_ERRO_GENERICO:
      return {
        ...state,
        errors: action.payload
      }

    case SET_ERROR_BAD_GATEWAY:
      return {
        ...state,
        errorBadGateway: action.payload
      }

    case CLEAR_STORED_ERROR: {
      return initialState
    }

    default:
      return state
  }
}
