import { lazy } from 'react'

// Lazy Componets
const Home = lazy(async () => await import('../pages/Home'))
const Consulta = lazy(async () => await import('../pages/Consulta'))
const PaginaNaoEncontrada = lazy(
  async () => await import('../components/NotFound')
)
const EscolhaUsuario = lazy(
  async () => await import('../pages/Consulta/EscolhaUsuario')
)
const MedicoEspecialidade = lazy(
  async () => await import('../pages/Consulta/MedicoEspecialidade')
)
const Pagamento = lazy(async () => await import('../pages/Consulta/Pagamento'))
const Paciente = lazy(async () => await import('../pages/Consulta/Paciente'))
const LocalConsulta = lazy(
  async () => await import('../pages/Consulta/LocalConsulta')
)
const AgendarConsulta = lazy(
  async () => await import('../pages/Consulta/Agendar-Consulta')
)
const ConfirmarHorario = lazy(
  async () => await import('../pages/Consulta/ConfirmarHorario')
)
const ConfirmarPaciente = lazy(
  async () => await import('../pages/Consulta/ConfirmarPaciente')
)
const SucessoAgendamento = lazy(
  async () => await import('../pages/Consulta/SucessoAgendamento')
)
const PaginaEmManutencao = lazy(
  async () => await import('../pages/Consulta/FallBacks/EmManutencao')
)
const SalaVideoconferenciaNaoDisponivel = lazy(
  async () =>
    await import(
      '../pages/Consulta/FallBacks/SalaVideoconferenciaNaoDisponivel'
    )
)
const TeleconsultaPaciente = lazy(
  async () => await import('../pages/Consulta/Teleconsulta/paciente')
)
const TeleconsultaMedico = lazy(
  async () => await import('../pages/Consulta/Teleconsulta/medico')
)
const CancelarConsulta = lazy(
  async () => await import('../pages/Consulta/CancelarConsulta')
)
const CancelarConsultaV2 = lazy(
  async () => await import('../pages/Consulta/CancelarConsulta/v2')
)
const ConsultaCanceladaComSucesso = lazy(
  async () => await import('../pages/Consulta/CancelarConsulta/sucesso')
)
const CancelarConsultaExpirada = lazy(
  async () => await import('../pages/Consulta/CancelarConsulta/expirada')
)
const SessaoExpirada = lazy(
  async () => await import('../pages/Consulta/SessaoExpirada')
)
const Historico = lazy(async () => await import('../pages/Consulta/Historico'))
const DadosPaciente = lazy(
  async () => await import('pages/Consulta/DadosPaciente')
)

export {
  Home,
  Consulta,
  PaginaNaoEncontrada,
  EscolhaUsuario,
  MedicoEspecialidade,
  Pagamento,
  Paciente,
  LocalConsulta,
  AgendarConsulta,
  ConfirmarHorario,
  ConfirmarPaciente,
  SucessoAgendamento,
  PaginaEmManutencao,
  SalaVideoconferenciaNaoDisponivel,
  TeleconsultaPaciente,
  TeleconsultaMedico,
  CancelarConsulta,
  CancelarConsultaV2,
  ConsultaCanceladaComSucesso,
  CancelarConsultaExpirada,
  SessaoExpirada,
  Historico,
  DadosPaciente
}
