import {
  BadgeNumber,
  BadgeNumberKeys
} from '../../components/FilterMobile/types'
import { FiltroListagemKeys } from '../../pages/Consulta/Agendar-Consulta/interfaces'

export const UPDATE_FILTER = 'UPDATE_FILTER'

export interface FiltersAction {
  type: string
  payload: FilterPayloadProps
}

export interface FilterPayloadProps {
  filterSelected?: FiltroListagemKeys
  filter: FiltroListagemKeys
  badgeNumber: BadgeNumber | BadgeNumberKeys
}
