import { Icon } from '@rededor/crdsl'
import React from 'react'
import styled, { css } from 'styled-components'

import StepbarInitial from 'assets/svgs/stepbar-initial.svg'
import StepbarLoader from 'assets/svgs/stepbar-loading.svg'

export interface ProgressIconProps {
  type?:
    | 'initial'
    | 'actual'
    | 'selected'
    | 'finalised'
    | 'warning'
    | 'error'
    | 'actualMobile'
  borderColor?: string
  iconName?: string
  size?: 'xxl' | 'xl' | 'lg' | 'md' | 'xs' | 'xxs'
  color?: string
}

const ProgressIconBackground = styled.div<ProgressIconProps>`
  ${({ type, borderColor }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
    padding: 5px;

    ${type === 'error' &&
    `
      background-color: #C43B51;
      border-radius: 50%;
    `}

    ${type === 'finalised' &&
    `
      background-color: #004186;
      border-radius: 50%;
    `}

    ${type === 'warning' &&
    `
      background-color: #DF8D2C;
      border-radius: 50%;
    `}

    ${borderColor &&
    `
      border-radius: 50%;
      border: 2px solid ${borderColor};
    `}

    ${!type &&
    `
      padding: 0;
    `}
  `}
`

const IconMobileRotate = styled(Icon)`
  transform: rotate(-45deg);
`

const MobileActual = styled.div`
  padding: 3px;
  margin-top: 1px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  color: #004186;
  transform: rotate(45deg);
  justify-content: center;
  background: #004186;

  & ${IconMobileRotate} {
    padding: 3px;
  }
`

const ProgressIcon = ({
  type,
  borderColor,
  iconName,
  size,
  color
}: ProgressIconProps) => (
  <>
    <ProgressIconBackground type={type} borderColor={borderColor}>
      {type === 'actual' && <img src={StepbarLoader} alt="Passo em aguardo" />}
      {type === 'initial' && (
        <img src={StepbarInitial} alt="Passo em aguardo" />
      )}
      {type === 'error' && (
        <Icon name="fechar" color="white" size={size || 'xs'} />
      )}
      {type === 'finalised' && (
        <Icon name="check" color="white" size={size || 'xs'} />
      )}
      {type === 'warning' && (
        <Icon name="alertaCirc" color="white" size={size || 'xs'} />
      )}
      {!type && color && (
        <Icon name={iconName} color={color} size={size || 'lg'} />
      )}
      {!type && !color && <Icon name={iconName} size={size || 'lg'} />}
    </ProgressIconBackground>
    {type === 'actualMobile' && (
      <MobileActual>
        {' '}
        <IconMobileRotate name={iconName} color="#f2f2f2" size={size || 'xs'} />
      </MobileActual>
    )}
  </>
)

export default ProgressIcon
