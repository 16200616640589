import styled, { css, keyframes } from 'styled-components'
import { Button as ButtonDS, Icon as IconDS } from '@rededor/crdsl'
import { Color } from '../../shared/Color'
import { srOnly } from '../../shared/Styles/Utils'
import { ButtonChatDefaultProps } from './types'

const MAX_WIDTH_MOBILE = '62rem'
const BUTTON_MARGIN = '20px'

const bounce = keyframes`
  from {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
    visibility: hidden;
    opacity: 0;
  }
      
  20% {
    animation-timing-function: linear;
    transform: translate3d(0, 0, 0);
    visibility: visible;
    opacity: 1;
  }
      
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  
  40%,
  43% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -30px, 0) scaleY(1.1);
  }

  70% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -15px, 0) scaleY(1.05);
  }

  80% {
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0, 0) scaleY(0.95);
  }

  90% {
      transform: translate3d(0, -4px, 0) scaleY(1.02);
  }

  to {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0, 0, 0);
  }
`

const woble = keyframes`
  from,
  to {
    transform-origin: bottom center;
    transform: scale(1);
  }
  
  40% {
    transform-origin: bottom center;
    transform: scale(1.25);
  }
    
  75% {
      transform-origin: bottom center;
      transform: scale(0.85);
  }   
`

const bounceAnimationMobile = css`
  animation: ${bounce} 0.5s;
  animation-fill-mode: forwards;
`

const bounceAnimationDesk = css`
  animation: ${woble} 0.5s;
`

const getOffsetBottomDesktop = (offsetBottom = 0) => {
  return `${offsetBottom}px`
}

const getOffsetBottomMobile = (offsetBottom = 0) => {
  return `calc(${BUTTON_MARGIN} + ${offsetBottom}px + env(safe-area-inset-bottom))`
}

export const Button = styled(ButtonDS)<ButtonChatDefaultProps>`
  position: fixed;
  right: ${BUTTON_MARGIN};
  bottom: ${props => getOffsetBottomMobile(props.offsetBottom)};
  background: linear-gradient(
      265.44deg,
      rgba(145, 216, 246, 0.06) 1.46%,
      rgba(145, 216, 246, 0.3) 105.09%
    ),
    linear-gradient(0deg, #004186, #004186);
  border: 0;
  border-radius: 50%;
  padding: 0;
  min-width: auto;
  min-height: auto;
  height: 51px;
  font-size: 0.875rem;
  z-index: 4;
  transform: translate3d(0, 0, 0);
  visibility: ${props => props.visible};
  opacity: ${props => (props.visible ? 1 : 0)};
  display: ${props => (props.visible ? 'initial' : 'none')};
  ${props => (props.animate && props.visible ? bounceAnimationMobile : 'none')};

  @media screen and (min-width: ${MAX_WIDTH_MOBILE}) {
    right: ${BUTTON_MARGIN};
    bottom: ${props => getOffsetBottomDesktop(props.offsetBottom)};
    border-bottom: 2px solid ${Color.Azul_Claro};
    border-radius: 4px 4px 0 0;
    display: inline-flex;
    padding: 0 29px 0 5px;
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    ${props => (props.animate ? bounceAnimationDesk : '')};
  }
`

export const Label = styled.span`
  @media screen and (max-width: ${MAX_WIDTH_MOBILE}) {
    ${srOnly}
  }

  @media screen and (min-width: ${MAX_WIDTH_MOBILE}) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding-left: 10px;
    border-left: 1px solid ${Color.Azul_Escuro};
  }
`

export const Icon = styled(IconDS)`
  margin: 0;

  @media screen and (min-width: ${MAX_WIDTH_MOBILE}) {
    margin-right: 3px;
  }
`

export const WrapperIcon = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
`
