import TextBase from 'components/TextBase'
import React from 'react'
import { LoadingMessageProp } from '../../interface'

export const LoadingMessage = ({
  title,
  message,
  colorMessage
}: LoadingMessageProp) => {
  return (
    <>
      <TextBase
        fontSize={18}
        fontSizeSmall={16}
        fontWeight="500"
        color="#004186"
        padding="15px 0 0"
        textAlign="center"
      >
        {title}
      </TextBase>
      <TextBase
        color={colorMessage}
        fontSize={15}
        fontSizeSmall={14}
        fontWeight="400"
        display="block"
        textAlign="center"
      >
        {message}
      </TextBase>
    </>
  )
}
