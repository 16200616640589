import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { ATUALIZA_ESPECIALIDADE } from './types'
import { AppState } from '../index'
import { Especialidade } from '../../models/Especialidade'

export const atualizaEspecialidade =
  (
    especialidade?: Especialidade
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: ATUALIZA_ESPECIALIDADE,
      payload: especialidade
    })
  }
