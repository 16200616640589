import React from 'react'
import styled from 'styled-components'
import TextBase from '../../TextBase/index'
import ColumnPercent from '../../Columns/ColumnPercent'
import { store } from '../../../store'

const Bold = styled.strong`
  font-weight: 500;
`

export function TooltipMedico() {
  return (
    <>
      <ColumnPercent hideLarge>
        <TextBase
          width={100}
          fontSize={14}
          fontWeight={500}
          color="#4f4f4f"
          margin="0 0 8px 0"
        >
          Escolha sua Especialidade / Profissional
        </TextBase>
      </ColumnPercent>
      <TextBase
        width={100}
        fontSize={11}
        fontSizeSmall={12}
        fontWeight={300}
        color="#828282"
        margin="0 0 5px 0"
        paddingSmall="0 0 10px"
      >
        Deseja realizar o agendamento para o <Bold>profissional</Bold>{' '}
        selecionado?
      </TextBase>
    </>
  )
}

export function TooltipEspecialidade() {
  return (
    <>
      <ColumnPercent hideLarge>
        <TextBase
          width={100}
          fontSize={14}
          fontWeight={500}
          color="#4f4f4f"
          margin="0 0 8px 0"
        >
          Escolha sua Especialidade
        </TextBase>
      </ColumnPercent>
      <TextBase
        width={100}
        fontSize={11}
        fontSizeSmall={12}
        fontWeight={300}
        color="#828282"
        margin="0 0 5px 0"
        paddingSmall="0 0 10px"
      >
        Deseja realizar o agendamento para a <Bold>especialidade</Bold>{' '}
        selecionada?
      </TextBase>
    </>
  )
}

export function TooltipLocal() {
  const { local } = store.getState().consulta

  return (
    <>
      <ColumnPercent hideLarge>
        <TextBase
          width={100}
          fontSize={14}
          fontWeight={500}
          color="#4f4f4f"
          margin="0 0 8px 0"
        >
          Escolha seu Local
        </TextBase>
      </ColumnPercent>
      <TextBase
        width={100}
        fontSize={11}
        fontSizeSmall={12}
        color="#828282"
        margin="0 0 5px 0"
        paddingSmall="0 0 10px"
      >
        Notamos que você está em <Bold>{String(local?.nome)}</Bold>, deseja
        realizar o agendamento para o local selecionado?
      </TextBase>
    </>
  )
}

export function TooltipPagamento() {
  return (
    <>
      <ColumnPercent hideLarge>
        <TextBase
          width={100}
          fontSize={14}
          fontWeight={500}
          color="#4f4f4f"
          margin="0 0 8px 0"
        >
          Escolha o seu Plano
        </TextBase>
      </ColumnPercent>
      <TextBase
        width={100}
        fontSize={11}
        fontSizeSmall={12}
        color="#828282"
        margin="0 0 5px 0"
        paddingSmall="0 0 10px"
      >
        Deseja realizar o agendamento para o <Bold>plano</Bold> selecionado?
      </TextBase>
    </>
  )
}

export function TooltipPaciente() {
  return (
    <>
      <ColumnPercent hideLarge>
        <TextBase
          width={100}
          fontSize={14}
          fontWeight={500}
          color="#4f4f4f"
          margin="0 0 8px 0"
        >
          Escolha o Paciente
        </TextBase>
      </ColumnPercent>
      <TextBase
        width={100}
        fontSize={11}
        fontSizeSmall={12}
        color="#828282"
        margin="0 0 5px 0"
        paddingSmall="0 0 10px"
      >
        Deseja realizar o agendamento para o paciente com dados informados?
      </TextBase>
    </>
  )
}

export function TooltipAgendamento() {
  return (
    <>
      <ColumnPercent hideLarge>
        <TextBase
          width={100}
          fontSize={14}
          fontWeight={500}
          color="#4f4f4f"
          margin="0 0 8px 0"
        >
          Agende sua Consulta
        </TextBase>
      </ColumnPercent>
      <TextBase
        width={100}
        fontSize={11}
        fontSizeSmall={12}
        color="#828282"
        margin="0 0 5px 0"
        paddingSmall="0 0 10px"
      >
        <Bold>Tudo pronto!</Bold> Deseja realizar o agendamento para a{' '}
        <Bold>consulta</Bold>?
      </TextBase>
    </>
  )
}
