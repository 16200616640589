import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { ATUALIZA_PACIENTE } from './types'
import { AppState } from '../index'
import { Paciente } from '../../models/Paciente'

export const atualizaPaciente =
  (
    paciente?: Partial<Paciente>
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: ATUALIZA_PACIENTE,
      payload: paciente
    })
  }
