import { INITIAL_STATE } from './settings'
import { FiltersAction, UPDATE_FILTER, FilterPayloadProps } from './types'

export function filterReducer(
  state = INITIAL_STATE as FilterPayloadProps,
  action: FiltersAction
) {
  switch (action.type) {
    case UPDATE_FILTER:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}
