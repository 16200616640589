import React from 'react'
import {
  TooltipAgendamento,
  TooltipEspecialidade,
  TooltipLocal,
  TooltipMedico,
  TooltipPaciente,
  TooltipPagamento
} from 'components/StepBar/TooltipContent'
import { store } from 'store'
import { StepName } from 'store/stepbar/types'
import { history } from '../browserhistory'
import DataHelper from './dataHelper'
import { StepbarTooltip } from './stepBarHelpers/types'
import { MemoryHistory } from 'history'

const browserHistory = history as MemoryHistory

const constroeTooltipDePagamento = (): StepbarTooltip => {
  const { pagamento } = store.getState().consulta
  const planoNome = String(pagamento?.plano?.nome)
  const convenioNome = String(pagamento?.convenio?.nome)
  const isParticular = pagamento?.particular

  const tooltipOptions = [
    { name: isParticular ? planoNome : convenioNome },
    {
      name: 'Outro Plano ou Carteirinha',
      url: '/paciente/marcar-consulta/pagamento',
      action: () => {
        browserHistory.push('/paciente/marcar-consulta/pagamento')
      }
    }
  ]

  if (!isParticular) {
    tooltipOptions.splice(1, 0, { name: planoNome })
  }

  return {
    title: <TooltipPagamento />,
    tooltipOptions
  }
}

const constroeTooltipDePaciente = (): StepbarTooltip => {
  const { paciente } = store.getState().consulta
  const genero = String(paciente?.genero) === 'M' ? 'Masculino' : 'Feminino'
  const dataNascimento = String(paciente?.dataNascimento)
  const dataFormatada =
    paciente && dataNascimento ? DataHelper.getDateToPTBR(dataNascimento) : ''

  return {
    title: <TooltipPaciente />,
    tooltipOptions: [
      { name: dataFormatada },
      { name: genero, hideSmall: true },
      {
        name: 'Mudar Dados',
        etapa: 'paciente',
        url: '/paciente/marcar-consulta/paciente',
        action: () => {
          browserHistory.push('/paciente/marcar-consulta/paciente')
        }
      },
      { name: genero, hideLarge: true }
    ]
  }
}

const constroeTooltipDeAgendamento = (): StepbarTooltip => {
  const { medico, especialidade, local, horario, pagamento, escolhaPorMedico } =
    store.getState().consulta

  const tooltipOptions = [
    {
      name: String(
        escolhaPorMedico === true ? medico?.nome : especialidade?.descricao
      ),
      url: 'especialidade-medico',
      etapa: 'especialidade-medico',
      widthSmall: '100%',
      action() {
        browserHistory.push(this.url)
      }
    }
  ]

  if (local && escolhaPorMedico !== true) {
    tooltipOptions.push({
      name: String(local.nome),
      url: 'local',
      etapa: 'local',
      widthSmall: '100%',
      action: () => {
        browserHistory.push('local')
      }
    })
  }

  if (horario) {
    const dataResumida = DataHelper.obtemDataResumida(
      String(horario.periodo.inicio)
    )
    tooltipOptions.push({
      name: dataResumida,
      url: 'agendar-consulta',
      etapa: 'agendar-consulta',
      widthSmall: '100%',
      action: () => {
        browserHistory.push('agendar-consulta')
      }
    })
  }

  tooltipOptions.push({
    name: String(pagamento?.plano?.nome),
    url: 'pagamento',
    etapa: 'pagamento',
    widthSmall: '100%',
    action: () => {
      browserHistory.push('pagamento')
    }
  })

  return {
    title: <TooltipAgendamento />,
    tooltipOptions
  }
}

const constroeTooltipDeLocal = (): StepbarTooltip => {
  const { local } = store.getState().consulta

  return {
    title: <TooltipLocal />,
    tooltipOptions: [
      { name: String(local?.nome) },
      {
        name: 'Outra Unidade',
        etapa: 'local',
        url: '/paciente/marcar-consulta/local',
        action: () => {
          browserHistory.push('/paciente/marcar-consulta/local')
        }
      }
    ]
  }
}

const constroeTooltipDeMedicoEspecialidade = (): StepbarTooltip => {
  const { escolhaPorMedico, medico, especialidade } = store.getState().consulta

  if (escolhaPorMedico === true) {
    return {
      title: <TooltipMedico />,
      tooltipOptions: [
        { name: String(medico?.nome) },
        {
          name: 'Outro Profissional',
          etapa: 'especialidade-medico',
          url: 'especialidade-medico',
          action: () => {
            browserHistory.push('especialidade-medico')
          }
        }
      ]
    }
  }

  return {
    title: <TooltipEspecialidade />,
    tooltipOptions: [
      { name: String(especialidade?.descricao) },
      {
        name: 'Outra Especialidade',
        etapa: 'especialidade-medico',
        url: 'especialidade-medico',
        action: () => {
          browserHistory.push('especialidade-medico')
        }
      }
    ]
  }
}

export const constroeTooltip = (stepAlvo: StepName): StepbarTooltip => {
  const tooltipOptions = {
    pagamento: constroeTooltipDePagamento,
    paciente: constroeTooltipDePaciente,
    local: constroeTooltipDeLocal,
    'especialidade-medico': constroeTooltipDeMedicoEspecialidade,
    'agendar-consulta': constroeTooltipDeAgendamento
  }

  const tooltip =
    tooltipOptions[stepAlvo] || constroeTooltipDeMedicoEspecialidade

  return tooltip()
}
