import { AcessoLogado } from '../../models/AcessoLogado'
import {
  LOGAR_USUARIO,
  DESLOGAR_USUARIO,
  AcessoLogadoAction,
  acessoLogadoInitialState,
  SELECIONAR_USUARIO
} from './types'

export function acessoLogadoReducer(
  state = acessoLogadoInitialState,
  action: AcessoLogadoAction
): AcessoLogado {
  switch (action.type) {
    case LOGAR_USUARIO:
      return {
        ...state,
        ...action.payload
      }
    case DESLOGAR_USUARIO:
      return {
        ...state,
        ...action.payload
      }
    case SELECIONAR_USUARIO:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}
