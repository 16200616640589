import { useCallback, useEffect, useState } from 'react'
import { CookieBannerEvent, CookieBannerHook } from './types'

declare global {
  interface WindowEventMap {
    cookieBannerChange: CookieBannerEvent
  }
}

export const useCookieBannerStatus = (): CookieBannerHook => {
  const [cookieBannerState, setCookieBannerState] = useState<
    CookieBannerEvent['detail']
  >({ el: document.body, state: 'invisible' })

  const cookieBannerChangeListener = useCallback((evt: CookieBannerEvent) => {
    const { detail } = evt
    setCookieBannerState(detail)
  }, [])

  useEffect(() => {
    window.addEventListener('cookieBannerChange', cookieBannerChangeListener)
  }, [cookieBannerChangeListener])

  return { cookieBannerState }
}
