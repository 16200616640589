import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import {
  StepBar,
  START_STEP,
  CHANGE_MULTI_STEP_STATUS,
  ChangeStatusStepBarMulti
} from './types'
import { AppState } from '../index'

export const handlerStartStepBar =
  (stepBar: StepBar): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    if (stepBar) {
      dispatch({
        type: START_STEP,
        payload: stepBar
      })
    }
  }

export function changeMultiStepBarStatus(
  changeStepBar: ChangeStatusStepBarMulti
) {
  return {
    type: CHANGE_MULTI_STEP_STATUS,
    meta: changeStepBar
  }
}
