import { isAfter } from 'date-fns'

/** Armazenamento Helper */
class ArmazenamentoHelper {
  private static readonly PREFIXO_CHAVE = `${process.env.REACT_APP_APPLICATION_NAME}`

  /**
   * Monta a chave junto
   * com o prefixo
   *
   * @param {string} chave
   * @returns {string}
   */
  private static readonly montaChaveCompleta = (chave: string): string =>
    `${ArmazenamentoHelper.PREFIXO_CHAVE}-#-${chave}`

  /**
   * Monta chave prefixada
   * com a validade
   *
   * @param {string} chave
   * @returns {string}
   */
  private static readonly montaChaveDeValidade = (chave: string): string =>
    `${ArmazenamentoHelper.montaChaveCompleta(chave)}:|:validade`

  /**
   * Armazena item 'de para'
   * no local storage
   *
   * @param {string} chave
   * @param {string} valor
   * @param {Date} validade
   */
  public static armazenaNoLocalStorage = (
    chave: string,
    valor: string,
    validade?: Date
  ): void => {
    localStorage.setItem(ArmazenamentoHelper.montaChaveCompleta(chave), valor)
    if (validade) {
      localStorage.setItem(
        ArmazenamentoHelper.montaChaveDeValidade(chave),
        String(validade.getTime())
      )
    }
  }

  public static armazenaNoSessionStorage = (
    chave: string,
    valor: string
  ): void => {
    sessionStorage.setItem(ArmazenamentoHelper.montaChaveCompleta(chave), valor)
  }

  public static recuperaDoLocalStorage = (chave: string): string | null => {
    const validade = localStorage.getItem(
      ArmazenamentoHelper.montaChaveDeValidade(chave)
    )
    if (validade) {
      const dataValidade = new Date(Number(validade))
      if (isAfter(new Date(), dataValidade)) {
        ArmazenamentoHelper.removeDoLocalStorage(chave)

        return null
      }
    }

    return localStorage.getItem(ArmazenamentoHelper.montaChaveCompleta(chave))
  }

  public static recuperaDoSessionStorage = (chave: string): string | null => {
    return sessionStorage.getItem(ArmazenamentoHelper.montaChaveCompleta(chave))
  }

  public static removeDoLocalStorage = (chave: string): void => {
    localStorage.removeItem(ArmazenamentoHelper.montaChaveCompleta(chave))
    localStorage.removeItem(ArmazenamentoHelper.montaChaveDeValidade(chave))
  }

  public static removeDoSessionStorage = (chave: string): void => {
    sessionStorage.removeItem(ArmazenamentoHelper.montaChaveCompleta(chave))
  }
}

export default ArmazenamentoHelper
