import RequisicaoAdapter from '../adapters/RequisicaoAdapter'
import avatarUsuario from '../shared/consts/avatarUsuario'
import endpoints from '../shared/consts/endpoints'
import { Usuario } from '../models/Usuario'
import { settings } from '../shared/consts/settings'
import { useInputBirthDate } from '../components/Inputs/InputBirthDate/controllers'
import { Genero } from '../models/Genero'

class UsuarioService {
  static async obtemInformacoesPeloToken(
    token: string
  ): Promise<Usuario[] | undefined> {
    const url = endpoints.usuario.informacoes.viaToken
    const { APP_CHANNEL, APP_NAME, CLIENT_ID } = settings
    const { checkIsValidDate } = useInputBirthDate()

    const { data } = await RequisicaoAdapter.request<Usuario[]>({
      url,
      headers: {
        client_id: CLIENT_ID,
        access_token: token,
        'x-origin-channel': APP_CHANNEL,
        'x-origin-application': APP_NAME
      }
    })

    const usuario = data.map(usuario => ({
      ...usuario,
      avatar: usuario.avatar || avatarUsuario,
      dataNascimento: checkIsValidDate(usuario.dataNascimento).valid
        ? usuario.dataNascimento.split('/').reverse().join('-')
        : '',
      genero: ['M', 'F'].includes(usuario.genero)
        ? usuario.genero
        : ('' as Genero)
    }))

    return usuario
  }
}

export default UsuarioService
