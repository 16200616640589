import { GenericAbortSignal } from 'axios'
import { format, parseISO } from 'date-fns'
import { store } from '../../../store'
import { ptBR } from 'date-fns/locale'
import { Horarios } from 'models/Horario'
import {
  DiaOferta,
  DiaOfertaBackend,
  HorarioOfertaBackend,
  HorarioStatus,
  UnidadeOfertaBackend
} from 'models/HorarioOferta'
import { AgendamentoHelper } from 'pages/Consulta/Agendar-Consulta/helpers'
import OfertaService from 'services/ofertaService'
import {
  ParamsListagemHorariosPorEspecialidade,
  ParamsListagemHorariosPorMedico,
  PeriodoOfertas
} from './interfaces'
import { useStepBar } from '../stepBarHelpers/stepbarHelpers'

export const converteTimeZoneBr = (dateToConvert: string | Date): Date => {
  const HourMilliseconds = 3600000
  const minutesInMilliseconds = 60000
  const offsetUtc = -3 // UTC of Brasil

  const date = new Date(dateToConvert) || new Date()
  const localTime = date.getTime()
  const localOffset = date.getTimezoneOffset() * minutesInMilliseconds
  const utc = localTime + localOffset
  const brasil = utc + HourMilliseconds * offsetUtc
  const timeNow = new Date(brasil)

  return timeNow
}

export const toBrTimeZone = (dateToConvert: string | Date): Date => {
  const date = new Date(dateToConvert) || new Date()

  const timeZoneInMinutes = 180

  if (date.getTimezoneOffset() !== timeZoneInMinutes) {
    const convertedDate = converteTimeZoneBr(date)

    return convertedDate
  }

  return date
}

export const obtemHorarioSemDia = (horaComData: string) => {
  const dateConverted = toBrTimeZone(horaComData).toISOString()
  const horaFormatadaParaData: Date = parseISO(dateConverted)

  return format(horaFormatadaParaData, 'HH:mm', { locale: ptBR })
}

export const obtemPeriodoPorExtenso = (periodo?: PeriodoOfertas): string => {
  if (!periodo) {
    return ''
  }

  const dataFormatada = (data: Date) =>
    format(data, `d' de 'LLLL`, { locale: ptBR })

  const inicio = toBrTimeZone(periodo.inicio)
  const fim = toBrTimeZone(periodo.fim)

  return `${dataFormatada(inicio)} a ${dataFormatada(fim)}`.toLowerCase()
}

export const converteHorariosEmDiaOferta = (
  horarios: Horarios
): DiaOferta[] => {
  return Object.values(horarios)
}

export const converteDiaOfertaEmHorarios = (
  horarios: DiaOferta[]
): Horarios => {
  return horarios.reduce(
    (horariosConvertidos, horario) => ({
      ...horariosConvertidos,
      [horario.data]: {
        data: horario.data,
        plano: horario.plano,
        especialidade: horario.especialidade,
        medicos: horario.medicos
      }
    }),
    {}
  )
}

export const useHorariosHelper = () => {
  const { navigateTo } = useStepBar()

  const recuperarHorarios = async (
    periodo: PeriodoOfertas,
    chaveDeCancelamento?: GenericAbortSignal
  ): Promise<Horarios | string> => {
    const { escolhaPorEspecialidade } = store.getState().consulta

    const modalidadeAgenda: 'medico' | 'especialidade' = escolhaPorEspecialidade
      ? 'especialidade'
      : 'medico'

    let parametros:
      | ParamsListagemHorariosPorMedico
      | ParamsListagemHorariosPorEspecialidade

    const inicio = toBrTimeZone(periodo.inicio)
    const fim = toBrTimeZone(periodo.fim)
    const dataFormatadaInicio = format(inicio, 'yyyy-MM-dd', { locale: ptBR })
    const dataFormatadaFim = format(fim, 'yyyy-MM-dd', { locale: ptBR })

    if (escolhaPorEspecialidade) {
      parametros =
        AgendamentoHelper.constroeParametrosDoEndpointPorEspecialidade(
          dataFormatadaInicio,
          dataFormatadaFim
        )
    } else {
      parametros = AgendamentoHelper.constroeParametrosDoEndpointPorMedico(
        dataFormatadaInicio,
        dataFormatadaFim
      )
    }

    if (!parametros.dataNascimento) {
      navigateTo('paciente')
      return ''
    }

    if (!parametros.plano) {
      navigateTo('pagamento')
      return ''
    }

    return await OfertaService.obtemAgenda(
      modalidadeAgenda,
      parametros,
      chaveDeCancelamento
    )
  }

  return { recuperarHorarios }
}

export const horarioJaFoiCancelado = (
  horarioStatus: HorarioStatus
): boolean => {
  return horarioStatus === 'CANCELADA'
}

export const horarioJaTeveSeuInicio = (
  horarioStatus: HorarioStatus
): boolean => {
  return horarioStatus === 'VENCIDA'
}

export const horarioPodeSerCancelado = (
  horarioStatus: HorarioStatus
): boolean => {
  return (
    !horarioJaTeveSeuInicio(horarioStatus) &&
    !horarioJaFoiCancelado(horarioStatus)
  )
}

export const unidadePossuiHorario = (ofertas: DiaOferta[]) => {
  const unidades: Array<string | number> = []

  ofertas.map(arr => {
    return arr.medicos.map(arr => {
      return arr.unidades.map(arr => {
        if (!unidades.includes(arr.unidade.id)) {
          unidades.push(arr.unidade.id)
        }

        return arr
      })
    })
  })

  return unidades
}

export const getScheduleSlotsQtyByPeriod = (schedule?: string[]) => {
  if (!schedule || schedule.length === 0) return []
  const [DATE] = schedule[0].split('T')
  const DATE_AFTERNOON = new Date(`${DATE}T12:00-03:00`)
  const qtySlotsMorningEntries = schedule.filter(
    schedule => new Date(schedule) < DATE_AFTERNOON
  )
  const qtySlotsMorning = qtySlotsMorningEntries.length
  const qtySlotsAfternoon = schedule.length - qtySlotsMorning

  return [qtySlotsMorning, qtySlotsAfternoon]
}

const getMedicSchedule = (hospitals: UnidadeOfertaBackend[]) => {
  const appointmentSchedule = hospitals.reduce<HorarioOfertaBackend[]>(
    (acc, curr) => {
      return acc.concat(curr.horarios)
    },
    []
  )

  return appointmentSchedule
}

export const getDayScheduleSlots = (schedule?: DiaOfertaBackend) => {
  if (!schedule?.medicos || schedule.medicos.length === 0) return []

  const { medicos: medics } = schedule
  const dayScheduleEntries = []

  for (const medic of medics) {
    const schedule = getMedicSchedule(medic.unidades)
    dayScheduleEntries.push(...schedule)
  }

  const dayScheduleMapped = dayScheduleEntries.map(
    daySchedule => daySchedule.periodo.inicio
  )

  const daySchedule = Array.from(new Set(dayScheduleMapped))

  return daySchedule
}
