import { AppSteps } from 'shared/Types/AppSteps'

export const RESERVATION_EXPIRATION_LIMIT_IN_SECONDS = Number(
  process.env.REACT_APP_RESERVATION_EXPIRATION_LIMIT_IN_SECONDS
)

export const STEPS_TO_SHOW_MODAL: AppSteps[] = [
  'confirmar-horario',
  'confirmar-paciente'
]
