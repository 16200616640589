const capitalizeIsolatedName = (name: string): string => {
  const KEEP_LOWER_CASE = ['da', 'de', 'do', 'das', 'dos', 'e']
  if (KEEP_LOWER_CASE.includes(name)) {
    return name
  }
  if (name === "d'or") {
    return "D'Or"
  }

  const first = name.charAt(0).toUpperCase()
  const rest = name.substring(1)

  return `${first}${rest}`
}

export const textCapitalized = (text: string): string => {
  return text
    .toLowerCase()
    .split(' ')
    .map(nome => capitalizeIsolatedName(nome))
    .join(' ')
    .replace(
      /<em>(.*?)<\/em>/gi,
      /* istanbul ignore next  */
      l => {
        const withoutEm = l.replace(/<em>|<\/em>/g, '')

        return `<em>${withoutEm.charAt(0).toUpperCase()}${withoutEm.substring(
          1
        )}</em>`
      }
    )
}

export const capitalize = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}
