import { ThemeProvider } from '@rededor/crdsl'
import ApplicationLoader from 'components/ApplicationLoader'
import React, { Suspense } from 'react'
import { Route } from 'react-router-dom'
import { Consulta, Home, PaginaNaoEncontrada } from './index'
import { PageLoading } from 'pages/Consulta/PageLoading'
import { useSentryInit } from 'utils/helpers/SentryHelper'
import {
  PushNotificationComponent,
  PwaComponent,
  SocketComponent
} from '@rededor/ap-core-frontend-react'
import { obtemTokenDeAcessoLogado } from 'utils/helpers/acessoLogadoHelper'

export const RoutesApp = (): JSX.Element => {
  const { SentryRoutes } = useSentryInit()
  const token = obtemTokenDeAcessoLogado()

  return (
    <Suspense
      fallback={
        <ApplicationLoader>
          <PageLoading type="genericPageLoading" />
        </ApplicationLoader>
      }
    >
      <div id="google-recaptcha" />
      <PushNotificationComponent
        application={process.env.REACT_APP_APPLICATION_NAME || ''}
        clientId={process.env.REACT_APP_CLIENT_ID || ''}
        baseUrl={process.env.REACT_APP_BACKEND_AREA_PACIENTE || ''}
      />
      <PwaComponent urlServiceWorker="/paciente/service-worker.js" />
      <SocketComponent
        application={process.env.REACT_APP_APPLICATION_NAME || ''}
        clientId={process.env.REACT_APP_CLIENT_ID || ''}
        accessToken={token || ''}
        baseUrl={process.env.REACT_APP_BACKEND_AREA_PACIENTE || ''}
      >
        <ThemeProvider>
          <SentryRoutes>
            <Route index path="/" element={<Home />} />
            <Route path="/paciente/marcar-consulta" element={<Consulta />} />

            {[
              '/paciente/marcar-consulta/:step/:stepPedido?',
              '/PACIENTE/MARCAR-CONSULTA/:step/:stepPedido?'
            ].map(path => (
              <Route key="path" path={path} element={<Consulta />} />
            ))}

            <Route
              path="/paciente/marcar-consulta/pre-preenchida"
              element={<Consulta />}
            />
            <Route
              path="/paciente/marcar-consulta/teleconsulta/:usuario/:codigoDaRequisicao"
              element={<Consulta />}
            />

            <Route path="*" element={<PaginaNaoEncontrada />} />
          </SentryRoutes>
        </ThemeProvider>
      </SocketComponent>
    </Suspense>
  )
}
