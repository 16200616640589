import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  acessoLogadoEstaAtivo,
  etapaDeEscolhaDeUsuarioFoiConcluida
} from './acessoLogadoHelper'

export const obtemPrimeiraPaginaDaAplicacao = (): string => {
  if (acessoLogadoEstaAtivo() && !etapaDeEscolhaDeUsuarioFoiConcluida()) {
    return 'escolha-usuario'
  }

  return 'especialidade-medico'
}

export const useRedirecionamentoController = () => {
  const navigate = useNavigate()

  const direcionaParaSucesso = useCallback(() => {
    navigate(`${process.env.REACT_APP_PUBLIC_URL}/sucesso-agendamento`)
  }, [history])

  const redirecionaParaTelaDeManuntencao = (): void => {
    navigate(`${process.env.REACT_APP_PUBLIC_URL}/em-manutencao`)
  }

  return {
    direcionaParaSucesso,
    redirecionaParaTelaDeManuntencao
  }
}
