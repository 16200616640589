import { ATUALIZA_HORARIO } from '../horario/types'
import { ATUALIZA_PACIENTE } from '../paciente/types'
import { ATUALIZA_PAGAMENTO } from '../pagamento/types'
import { Consulta } from '../../models/Consulta'
import { ATUALIZA_ESPECIALIDADE } from '../especialidade/types'
import { ATUALIZA_MEDICO } from '../medico/types'
import {
  START_CONSULTA,
  ATUALIZA_ESCOLHA,
  ATUALIZA_MODALIDADE_ATENDIMENTO,
  LIMPA_CONSULTA,
  REINICIA_CONSULTA,
  ATUALIZA_CODIGO_REQUISICAO,
  ATUALIZA_CONFIRMACAO,
  ATUALIZA_ORIGEM_TELECONSULTA,
  ATUALIZA_LOCAL_SUGESTOES,
  LIMPA_LOCAL_SUGESTOES,
  ATUALIZA_FALLBACK_PERMITIDO,
  LIMPA_FALLBACK_PERMITIDO,
  DEFINE_METADATA,
  JA_LISTOU_HORARIO,
  REINICIA_CONSULTA_MESMO_PACIENTE,
  REINICIA_CONSULTA_MESMO_MEDICO_CANCELAMENTO,
  HAS_CONFIRMED_APPOINTMENT_DATA,
  HAS_EXPANDED_SUGGESTION,
  SET_EXPANSION_SUGGESTION,
  UPDATE_TOKEN,
  POSSUI_HORARIOS,
  SALVAR_VALIDACAO,
  LIMPAR_VALIDACAO,
  IS_LOADING
} from './types'
import { ATUALIZA_UNIDADE, ATUALIZA_UNIDADE_RDSL } from '../unidade/types'
import { ATUALIZA_LOCAL } from '../local/types'

const initialState: Consulta = {
  origemTeleconsulta: 'outro',
  estaConfirmada: false,
  localSugestoes: [],
  expansionSuggestion: [],
  fallbacksPermitidos: [],
  metaData: {
    primeiraListagemHorarios: '',
    ultimaListagemHorarios: '',
    quantidadeListagemHorarios: 0,
    hasConfirmedAppointmentData: false,
    hasExpandedSuggestions: false,
    resetAppointment: false
  },
  jaListouHorario: false,
  possuiHorario: false,
  isLoading: false,
  validacao: []
}

export function consultaReducer(state = initialState, action: any): Consulta {
  const dataAtual = new Date().toTimeString()

  switch (action.type) {
    case ATUALIZA_MEDICO:
      return {
        ...state,
        ultimoStepAtualizado: 'especialidade-medico',
        medico:
          typeof action.payload === 'undefined'
            ? undefined
            : { ...action.payload }
      }
    case ATUALIZA_ESPECIALIDADE:
      return {
        ...state,
        ultimoStepAtualizado: 'especialidade-medico',
        especialidade:
          typeof action.payload === 'undefined'
            ? undefined
            : { ...action.payload }
      }
    case ATUALIZA_PACIENTE:
      return {
        ...state,
        ultimoStepAtualizado: 'paciente',
        paciente:
          typeof action.payload === 'undefined'
            ? undefined
            : { ...action.payload }
      }
    case ATUALIZA_PAGAMENTO:
      return {
        ...state,
        ultimoStepAtualizado: 'pagamento',
        pagamento:
          typeof action.payload === 'undefined'
            ? undefined
            : { ...action.payload }
      }
    case ATUALIZA_HORARIO:
      return {
        ...state,
        ultimoStepAtualizado: 'agendar-consulta',
        horario:
          typeof action.payload === 'undefined'
            ? undefined
            : { ...action.payload }
      }
    case ATUALIZA_UNIDADE:
      return {
        ...state,
        ultimoStepAtualizado: 'agendar-consulta',
        unidade:
          typeof action.payload === 'undefined'
            ? undefined
            : { ...action.payload }
      }
    case ATUALIZA_UNIDADE_RDSL:
      return {
        ...state,
        unidadesRdsl: action.payload
      }
    case ATUALIZA_ESCOLHA:
      return {
        ...state,
        ...action.payload,
        ultimoStepAtualizado: 'especialidade-medico'
      }
    case ATUALIZA_MODALIDADE_ATENDIMENTO:
      return {
        ...state,
        ...action.payload,
        ultimoStepAtualizado: 'especialidade-medico'
      }
    case ATUALIZA_CODIGO_REQUISICAO:
      return {
        ...state,
        codigoRequisicao: action.payload
      }
    case ATUALIZA_LOCAL:
      return {
        ...state,
        ultimoStepAtualizado: 'local',
        local:
          typeof action.payload === 'undefined'
            ? undefined
            : { ...action.payload }
      }
    case START_CONSULTA:
      return {
        ...state,
        ...action.payload,
        ultimoStepAtualizado: undefined
      }
    case LIMPA_CONSULTA:
      return {}

    case REINICIA_CONSULTA:
      return {
        especialidade: undefined,
        medico: undefined,
        escolhaPorEspecialidade: false,
        agendamentoTeleconsulta: false,
        agendamentoViaSite: false,
        escolhaPorMedico: false,
        local: undefined,
        paciente: undefined,
        pagamento: undefined,
        unidade: undefined,
        horario: undefined,
        ultimoStepAtualizado: 'especialidade-medico'
      }

    case REINICIA_CONSULTA_MESMO_PACIENTE:
      return {
        ...state,
        agendamentoTeleconsulta: false,
        agendamentoViaSite: false,
        escolhaPorEspecialidade: false,
        especialidade: undefined,
        escolhaPorMedico: false,
        codigoRequisicao: undefined,
        ...(state.metaData && {
          metaData: {
            ...state.metaData,
            hasConfirmedAppointmentData: false,
            resetAppointment: true
          }
        }),
        ultimoStepAtualizado: 'especialidade-medico'
      }

    case REINICIA_CONSULTA_MESMO_MEDICO_CANCELAMENTO: {
      const { payload } = action

      return {
        ...payload,
        local: payload.unidadeBusca,
        unidadesRdsl: [payload.unidadeBusca.id.replace('H', '')],
        escolhaPorMedico: true,
        escolhaPorEspecialidade: false,
        agendamentoTeleconsulta: !!payload.especialidade?.isConsultaDigital,
        ultimoStepAtualizado: 'pagamento'
      }
    }

    case ATUALIZA_CONFIRMACAO:
      return {
        ...state,
        estaConfirmada: action.payload
      }

    case ATUALIZA_ORIGEM_TELECONSULTA:
      return {
        ...state,
        origemTeleconsulta: action.payload
      }

    case ATUALIZA_LOCAL_SUGESTOES:
      return {
        ...state,
        localSugestoes: action.payload
      }

    case ATUALIZA_FALLBACK_PERMITIDO:
      return {
        ...state,
        fallbacksPermitidos: [action.payload]
      }

    case LIMPA_LOCAL_SUGESTOES:
      return {
        ...state,
        localSugestoes: []
      }

    case LIMPA_FALLBACK_PERMITIDO:
      return {
        ...state,
        fallbacksPermitidos: []
      }

    case DEFINE_METADATA:
      return {
        ...state,
        metaData: {
          ...state.metaData,
          primeiraListagemHorarios:
            typeof state.metaData?.primeiraListagemHorarios === 'undefined'
              ? dataAtual
              : state.metaData?.primeiraListagemHorarios,
          ultimaListagemHorarios: dataAtual,
          quantidadeListagemHorarios:
            typeof state.metaData?.quantidadeListagemHorarios === 'undefined'
              ? 1
              : state.metaData.quantidadeListagemHorarios + 1,
          hasConfirmedAppointmentData:
            state.metaData?.hasConfirmedAppointmentData,
          resetAppointment: state.metaData?.resetAppointment
        }
      }

    case JA_LISTOU_HORARIO:
      return {
        ...state,
        jaListouHorario: action.payload
      }

    case POSSUI_HORARIOS:
      return {
        ...state,
        possuiHorario: action.payload
      }

    case IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }

    case HAS_CONFIRMED_APPOINTMENT_DATA:
      return {
        ...state,
        ...(state.metaData && {
          metaData: {
            ...state.metaData,
            hasConfirmedAppointmentData: action.payload
          }
        })
      }

    case HAS_EXPANDED_SUGGESTION:
      return {
        ...state,
        metaData: {
          ...state.metaData,
          hasExpandedSuggestions: action.payload
        }
      }

    case SET_EXPANSION_SUGGESTION:
      return {
        ...state,
        expansionSuggestion: action.payload
      }

    case UPDATE_TOKEN: {
      const tokenInfo = {
        token: action.payload.token,
        requestInfo: {
          isInvalidCode: action.payload.requestInfo?.isInvalidCode || false,
          isSuccessful: action.payload.requestInfo?.isSuccessful || false,
          isCodeExpire: action.payload.requestInfo?.isCodeExpire || false,
          loading: action.payload.requestInfo?.loading || false
        }
      }
      return {
        ...state,
        tokenInfo
      }
    }

    case SALVAR_VALIDACAO:
      return {
        ...state,
        validacao: action.payload
          ? [...(state.validacao || []), ...action.payload]
          : []
      }

    case LIMPAR_VALIDACAO:
      return {
        ...state,
        validacao: []
      }

    default:
      return state
  }
}
