export enum Color {
  Branco = '#FFF',
  Cinza = '#BDBDBD',
  CinzaClaro = '#E0E0E0',
  Cinza_Escuro = '#4F4F4F',
  Cinza_Medio = '#828282',
  Cinza_Fundo_Claro = '#F2F2F2',
  Azul_Claro = '#91D8F6',
  AzulClaroTransparente = 'rgba(145, 216, 246, 0.27)',
  Azul_Escuro = '#004186'
}
