import React, {
  PropsWithChildren,
  ReactNode,
  createContext,
  useContext
} from 'react'

import useOpenChatWhenInactive from 'shared/Hooks/useOpenChatWhenInactive'
import { settings } from 'pages/Consulta/settings'

export type OpenChatWhenInactiveProps = ReturnType<
  typeof useOpenChatWhenInactive
>

const OpenChatWhenInactiveContext = createContext(
  {} as OpenChatWhenInactiveProps
)

export const OpenChatWhenInactiveProvider = ({
  children
}: PropsWithChildren<ReactNode>) => {
  const { ACTIVITY_EVENTS, CHAT_DELAY_TIME } = settings

  const { chatIdleStatus, resetTimer, startTimer, stopTimer } =
    useOpenChatWhenInactive({
      inactivityTime: CHAT_DELAY_TIME,
      activityEvents: ACTIVITY_EVENTS
    })

  return (
    <OpenChatWhenInactiveContext.Provider
      value={{ chatIdleStatus, resetTimer, startTimer, stopTimer }}
    >
      {children}
    </OpenChatWhenInactiveContext.Provider>
  )
}

export const useOpenChatWhenInactiveContext = () => {
  const context = useContext(OpenChatWhenInactiveContext)

  if (!context) {
    throw new Error('useStorage must be used within a StorageProvider')
  }

  return context
}
