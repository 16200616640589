import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'

import {
  Consulta,
  EscolhaConsulta,
  ModalidadeAtendimento,
  OrigemTeleconsulta
} from 'models/Consulta'
import { LocalSugestaoBackend } from 'models/Local'
import { FallbackPermitido } from 'models/Fallback'
import { SugestaoDeUnidadesElegibilidade } from 'models/Unidade'
import {
  START_CONSULTA,
  LIMPA_CONSULTA,
  ATUALIZA_ESCOLHA,
  ATUALIZA_MODALIDADE_ATENDIMENTO,
  REINICIA_CONSULTA,
  ATUALIZA_CODIGO_REQUISICAO,
  ATUALIZA_CONFIRMACAO,
  ATUALIZA_ORIGEM_TELECONSULTA,
  ATUALIZA_LOCAL_SUGESTOES,
  ATUALIZA_FALLBACK_PERMITIDO,
  LIMPA_FALLBACK_PERMITIDO,
  DEFINE_METADATA,
  JA_LISTOU_HORARIO,
  REINICIA_CONSULTA_MESMO_PACIENTE,
  REINICIA_CONSULTA_MESMO_MEDICO_CANCELAMENTO,
  HAS_CONFIRMED_APPOINTMENT_DATA,
  HAS_EXPANDED_SUGGESTION,
  SET_EXPANSION_SUGGESTION,
  UPDATE_TOKEN,
  POSSUI_HORARIOS,
  IS_LOADING,
  SALVAR_VALIDACAO,
  LIMPAR_VALIDACAO
} from './types'
import { AppState } from '../index'
import { TokenInfoProps } from '../../models/TokenInfo'
import { ValidacaoElegibilidade } from '../../utils/helpers/elegibilidadeHelper'

export const limpaConsulta =
  (): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: LIMPA_CONSULTA,
      payload: {}
    })
  }

export const reiniciaConsulta =
  (): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: REINICIA_CONSULTA,
      payload: {}
    })
  }

export const reiniciaConsultaMesmoPaciente =
  (consulta: Consulta): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: REINICIA_CONSULTA_MESMO_PACIENTE,
      payload: { consulta }
    })
  }

export const restartAppointmentWithSameDoctor =
  (consulta: Consulta): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: REINICIA_CONSULTA_MESMO_MEDICO_CANCELAMENTO,
      payload: consulta
    })
  }

export const atualizaConsulta =
  (consulta: Consulta): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: START_CONSULTA,
      payload: consulta
    })
  }

export const atualizaEscolha =
  (
    escolha?: EscolhaConsulta
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: ATUALIZA_ESCOLHA,
      payload: escolha || {
        escolhaPorMedico: false,
        escolhaPorEspecialidade: false
      }
    })
  }

export const atualizaModalidadeAtendimento =
  (
    modalidadeAtendimento?: ModalidadeAtendimento
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: ATUALIZA_MODALIDADE_ATENDIMENTO,
      payload: modalidadeAtendimento || {
        agendamentoTeleconsulta: false
      }
    })
  }

export const atualizaCodigoRequisicao =
  (
    codigoRequisicao?: string
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: ATUALIZA_CODIGO_REQUISICAO,
      payload: codigoRequisicao
    })
  }

export const atualizaConfirmacao =
  (
    estaConfirmada: boolean
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: ATUALIZA_CONFIRMACAO,
      payload: estaConfirmada
    })
  }

export const atualizaOrigemTeleconsulta =
  (
    origem: OrigemTeleconsulta
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: ATUALIZA_ORIGEM_TELECONSULTA,
      payload: origem
    })
  }

export const atualizaLocalSugestoes =
  (
    sugestoes:
      | LocalSugestaoBackend[]
      | string[]
      | SugestaoDeUnidadesElegibilidade[] = []
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: ATUALIZA_LOCAL_SUGESTOES,
      payload: sugestoes
    })
  }

export const limpaLocalSugestoes =
  (): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: ATUALIZA_LOCAL_SUGESTOES
    })
  }

export const atualizaFallbackPermitido =
  (
    fallback: FallbackPermitido
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: ATUALIZA_FALLBACK_PERMITIDO,
      payload: fallback
    })
  }

export const limpaFallbackPermitido =
  (): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: LIMPA_FALLBACK_PERMITIDO
    })
  }

export const defineMetadata =
  (): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: DEFINE_METADATA
    })
  }

export const jaListouHorario =
  (
    jaListouHorario: boolean
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: JA_LISTOU_HORARIO,
      payload: jaListouHorario
    })
  }

export const possuiHorario =
  (
    possuiHorario: boolean
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: POSSUI_HORARIOS,
      payload: possuiHorario
    })
  }

export const isLoading =
  (isLoading: boolean): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: IS_LOADING,
      payload: isLoading
    })
  }

export const setHasConfirmedAppointmentData =
  (status: boolean): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: HAS_CONFIRMED_APPOINTMENT_DATA,
      payload: status
    })
  }

export const setHasExpandedSuggestion =
  (
    hasSuggestion: boolean
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: HAS_EXPANDED_SUGGESTION,
      payload: hasSuggestion
    })
  }

export const setExpansionSuggestion =
  (
    suggestionList: string[] = []
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: SET_EXPANSION_SUGGESTION,
      payload: suggestionList
    })
  }

export const updateToken =
  (
    tokenInfo: TokenInfoProps
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: UPDATE_TOKEN,
      payload: tokenInfo
    })
  }

export const salvarValidacao =
  (
    data: ValidacaoElegibilidade[]
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: SALVAR_VALIDACAO,
      payload: data
    })
  }

export const limparValidacaoElegibilidade =
  (): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: LIMPAR_VALIDACAO
    })
  }
