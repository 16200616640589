import { OpenChatWhenInactiveArgs } from 'shared/Hooks/useOpenChatWhenInactive/types'
import { AppSteps } from 'shared/Types/AppSteps'

const HIDE_STEPBAR_STEPS = [
  'historico',
  'teleconsulta',
  'sessao-expirada',
  'videoconferencia',
  'cancelar-consulta',
  'consulta-expirada',
  'consulta-cancelada',
  'sucesso-agendamento',
  'em-manutencao',
  'detalhes'
]

const OPEN_CHAT_WHEN_INACTIVE_STEPS: AppSteps[] = ['pagamento']
const CHAT_DELAY_TIME = 40
const ACTIVITY_EVENTS: OpenChatWhenInactiveArgs['activityEvents'] = [
  'click',
  'touchstart',
  'scroll',
  'keyup'
]

const FULL_WIDTH_WRAPPER = ['sessao-expirada', 'em-manutencao']
const NO_MARGIN_TO_WRAPPER = ['em-manutencao']

const VARIANTT_SESSION_KEY = 'google-optmize:identificacao-paciente'
const VARIANT_IDENTIFIER = 'variant-b'
const FIRST_STEP_VARIANT = 'id-paciente'
const FORBIDDEN_PARAMS_MAP = {
  utm_source: 'tuasaude.com',
  utm_campaign: ''
}

export const settings = {
  ACTIVITY_EVENTS,
  CHAT_DELAY_TIME,
  FIRST_STEP_VARIANT,
  FORBIDDEN_PARAMS_MAP,
  FULL_WIDTH_WRAPPER,
  HIDE_STEPBAR_STEPS,
  NO_MARGIN_TO_WRAPPER,
  OPEN_CHAT_WHEN_INACTIVE_STEPS,
  VARIANT_IDENTIFIER,
  VARIANTT_SESSION_KEY
}
