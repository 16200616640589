const TITLE_RESERVATION = 'Reserva Expirada'
const TITLE_ERROR = 'Algo deu errado.'
const TEXT =
  'O horário selecionado não está mais disponível. Escolha outro horário para seu agendamento.'
const BUTTON_LABEL = 'ESCOLHER NOVO HORÁRIO'

export const settings = {
  BUTTON_LABEL,
  TEXT,
  TITLE_RESERVATION,
  TITLE_ERROR
}
