import { AxiosRequestConfig } from 'axios'
import RequisicaoAdapter from '../adapters/RequisicaoAdapter'
import endpoints from '../shared/consts/endpoints'

import headersEndpointsAgendamento from 'shared/consts/headersEndpointAgendamento'
import { LocalSugestaoBackend } from '../models/Local'
import {
  HospitalSuggestionByCoordinatesParams,
  SugestaoDeUnidadesElegibilidade,
  SugestaoDeUnidadesProximasParams,
  SugestaoDeUnidadesProximasQueAtendamPlanoParams
} from '../models/Unidade'

class UnidadeService {
  private static async obtemSugestoes(
    modalidade: 'qualquerPagamento' | 'pagamentoComPlano',
    parametros:
      | SugestaoDeUnidadesProximasParams
      | SugestaoDeUnidadesProximasQueAtendamPlanoParams
  ): Promise<LocalSugestaoBackend[]> {
    const url = endpoints.unidade.sugestao[modalidade]

    return await RequisicaoAdapter.request<LocalSugestaoBackend[]>({
      url,
      params: { ...parametros }
    }).then(response => {
      return response.data
    })
  }

  static async obtemSugestoesParaQualquerPagamento(
    parametros: SugestaoDeUnidadesProximasParams
  ): Promise<LocalSugestaoBackend[]> {
    return await UnidadeService.obtemSugestoes('qualquerPagamento', parametros)
  }

  static async obtemSugestoesQueAtendamPlano(
    parametros: SugestaoDeUnidadesProximasQueAtendamPlanoParams
  ): Promise<LocalSugestaoBackend[]> {
    return await UnidadeService.obtemSugestoes('pagamentoComPlano', parametros)
  }

  static async getSuggestionByHospitalCoordinates(
    params: HospitalSuggestionByCoordinatesParams
  ): Promise<Array<Pick<SugestaoDeUnidadesElegibilidade, 'idRdsl'>>> {
    const url = endpoints.unidade.sugestao.byRadius
    const headers: AxiosRequestConfig['headers'] = {
      ...headersEndpointsAgendamento,
      client_id: `${process.env.REACT_APP_CLIENT_ID}`
    }

    return await RequisicaoAdapter.request<
      Array<Pick<SugestaoDeUnidadesElegibilidade, 'idRdsl'>>
    >({
      url,
      params,
      headers
    }).then(response => {
      return response.data
    })
  }
}

export default UnidadeService
