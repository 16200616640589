import React from 'react'
import styled from 'styled-components'

export interface ColumnPercentProps {
  width?: number | string
  height?: number
  minHeight?: number
  minWidth?: number
  smallWidth?: number
  smallHeight?: number
  hideSmall?: boolean
  hideLarge?: boolean
  alignItems?:
    | 'start'
    | 'center'
    | 'end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
  alignItemsSmall?:
    | 'start'
    | 'center'
    | 'end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch'
  justifyContent?:
    | 'start'
    | 'center'
    | 'end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  justifyContentSmall?:
    | 'start'
    | 'center'
    | 'end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
  alignContent?: 'start' | 'center' | 'end' | 'space-between'
  alignContentSmall?: 'start' | 'center' | 'end' | 'space-between'
  direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse'
  directionSmall?: 'column' | 'column-reverse' | 'row' | 'row-reverse'
  flex?: number | string
  nowrap?: boolean
  padding?: string
  paddingSmall?: string
  paddingTop?: string
  border?: string
  borderRight?: string | null
  borderTop?: string | null
  borderBottom?: string | null
  borderLeft?: string | null
  borderBottomLeftRadius?: string
  borderRadius?: string
  margin?: string
  marginSmall?: string
  removeFlex?: boolean
  children: React.ReactNode
  backgroundColor?: string
  background?: string
  position?: string
  hide?: boolean
  className?: string
  textAlign?: string
  gap?: number
  columnGap?: number
  rowGap?: number
  defaultWidth?: boolean
}

const parseAlign = (align?: string) =>
  align && /start|end/gi.test(align) ? `flex-${align}` : align

const getSize = (size: number | string) => {
  return typeof size === 'string' ? size : `${size}%`
}

const StyledColumnPercent = styled.div<ColumnPercentProps>`
  width: ${props => (props.width ? getSize(props.width) : 'auto')};
  ${({ removeFlex }) => !removeFlex && `display: flex;`};
  ${({ position }) => position && `position: ${position};`};
  ${({ flex }) => flex && `flex: ${flex};`};
  ${({ nowrap }) => `flex-wrap: ${nowrap ? 'nowrap' : 'wrap'}`};
  ${({ direction }) => direction && `flex-direction: ${direction}`};
  flex-direction: ${props => (props.direction ? props.direction : 'row')}
    /* ${({ width }) =>
      width &&
      `width: ${typeof width === 'string' ? `${width}` : `${width}%`}`}; */
    ${({ height }) => height && `height: ${height}%`};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px`};
  ${({ borderRight }) => borderRight && `border-right: ${borderRight}`};
  ${({ borderTop }) => borderTop && `border-top: ${borderTop}`};
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom}`};
  ${({ borderLeft }) => borderLeft && `border-left: ${borderLeft}`};
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom}`};
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
  ${({ borderBottomLeftRadius }) =>
    borderBottomLeftRadius &&
    `border-bottom-left-radius : ${borderBottomLeftRadius}`};
  align-items: ${({ alignItems }) => parseAlign(alignItems)};
  align-content: ${({ alignContent }) => parseAlign(alignContent)};
  justify-content: ${({ justifyContent }) => parseAlign(justifyContent)};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ border }) => border && `border: ${border}`};
  ${({ margin }) => margin && `margin: ${margin}`};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor}`};
  ${({ background }) => background && `background: ${background}`};
  gap: ${props => (props.gap ? `${props.gap}px` : '')};
  @media screen and (max-width: 62rem) {
    width: ${props => (props.smallWidth ? getSize(props.smallWidth) : '100%')};
    ${({ smallHeight }) =>
      smallHeight ? `height: ${smallHeight}%` : 'height: auto'};
    ${({ paddingSmall }) => paddingSmall && `padding: ${paddingSmall}`};
    ${({ marginSmall }) => marginSmall && `margin: ${marginSmall}`};
    ${({ justifyContentSmall }) =>
      justifyContentSmall
        ? `justify-content: ${parseAlign(justifyContentSmall)}`
        : 'justify-content: auto'};
    ${({ alignItemsSmall }) =>
      alignItemsSmall
        ? `align-items: ${parseAlign(alignItemsSmall)}`
        : 'align-items: auto'};
    align-content: ${({ alignContentSmall }) =>
      parseAlign(alignContentSmall) || 'auto'};
    border: none;
    flex-direction: ${props =>
      props.directionSmall ? props.directionSmall : props.direction || 'row'};
  }
  ${({ hideSmall }) =>
    hideSmall &&
    `
  @media screen and (max-width: 62rem) {
    display: none;
  }
  `};
  ${({ hideLarge }) =>
    hideLarge &&
    `
  @media screen and (min-width: 62rem) {
    display: none;
  }
  `};
  ${({ hide }) =>
    hide &&
    `
    display: none;
  `};
  ${({ nowrap }) =>
    nowrap &&
    `
  @media screen and (max-width: 85rem) {
    flex-wrap: nowrap;
  }
  `};
  ${({ defaultWidth }) =>
    defaultWidth &&
    `
  @media screen and (max-width: 70rem) {
    width: auto
  }
  `};
  ${({ columnGap }) =>
    columnGap &&
    `
    column-gap: ${columnGap}px
  `};
  ${({ rowGap }) =>
    rowGap &&
    `
    row-gap: ${rowGap}px
  `};
`

const ColumnPercent = ({ children, ...props }: ColumnPercentProps) => {
  return <StyledColumnPercent {...props}>{children}</StyledColumnPercent>
}

export default ColumnPercent
