import {
  StepBarState,
  START_STEP,
  CHANGE_MULTI_STEP_STATUS,
  StepBarActionTypes
} from './types'

const initialState: StepBarState = {
  stepbar: { step: 1, title: 'Teste', stepConfig: [] }
}

export function stepBarReducer(
  state = initialState,
  action: StepBarActionTypes
): StepBarState {
  switch (action.type) {
    case START_STEP:
      return {
        stepbar: action.payload
      }
    case CHANGE_MULTI_STEP_STATUS: {
      const { stepConfig } = state.stepbar
      let stepNumber = 1
      action.meta.config.forEach(m => {
        const { name, stepSelected, stepbarTarget } = m
        stepConfig.forEach((s, key) => {
          if (s.name === name) {
            Object.assign(s, stepSelected)
            if (stepbarTarget) {
              stepNumber = key + 1
            }
          }
        })
      })

      return {
        stepbar: { ...state.stepbar, stepConfig, step: stepNumber }
      }
    }
    default:
      return state
  }
}
