import React from 'react'
import { Helmet } from 'react-helmet'

class HelmetHelper {
  public static adicionaTituloDaPagina(prefixoDoTitulo?: string): JSX.Element {
    const sufixoBaseDoTitulo = `Agendamento de Consultas | Rede D'Or São Luiz`

    return (
      <Helmet>
        <title>
          {`${
            prefixoDoTitulo ? `${prefixoDoTitulo} - ` : ''
          }${sufixoBaseDoTitulo}`}
        </title>
      </Helmet>
    )
  }

  public static incorporaScriptsDoLgpd(): JSX.Element {
    const LGPD_STYLE_PATH =
      'https://cdn.privacytools.com.br/public_api/banner/style/5ZdG1241.css?t=1'

    const SCRIPT_LGPD = {
      defer: true,
      id: 'privacytoolsScript',
      src: 'https://cdn.privacytools.com.br/public_api/banner/script/bottom/5ZdG1241.js?t=1'
    }

    const SCRIPT_HACK = {
      id: 'privacytoolsScript_hack',
      src: 'cookie-consent-extend.js'
    }

    return (
      <Helmet>
        <link rel="stylesheet" type="text/css" href={`${LGPD_STYLE_PATH}`} />
        <script {...SCRIPT_LGPD}></script>
        <script {...SCRIPT_HACK}></script>
      </Helmet>
    )
  }

  public static injectScriptsGoogleOptimize() {
    const googleOptimizeMustBeInjected =
      process.env.REACT_APP_GOOGLE_OPTIMIZE_ACTIVE === 'true'

    if (!googleOptimizeMustBeInjected) return <React.Fragment />

    const idGoogleOptimize = process.env.REACT_APP_GOOGLE_OPTIMIZE_ID

    return (
      <Helmet>
        <script
          src={`https://www.googleoptimize.com/optimize.js?id=${idGoogleOptimize}`}
        />
      </Helmet>
    )
  }

  public static addMobileWebAppTitle() {
    return (
      <Helmet>
        <meta
          name="apple-mobile-web-app-title"
          content="Área do Paciente Rede D'Or"
        />
      </Helmet>
    )
  }
}

export default HelmetHelper
