import React from 'react'
import { useNavigate } from 'react-router-dom'
import { TextBaseProps } from './interfaces'
import { TextBaseStyled, TextButtonStyled } from './styles'

const TextBase = ({ children, ...props }: TextBaseProps) => {
  const navigate = useNavigate()
  if (props.link) {
    const LINK = `${process.env.REACT_APP_PUBLIC_URL}/${props.link}`

    return (
      <TextButtonStyled
        onClick={() => {
          navigate(LINK)
        }}
        {...props}
      >
        {children}
      </TextButtonStyled>
    )
  }

  return <TextBaseStyled {...props}>{children}</TextBaseStyled>
}

export default TextBase
