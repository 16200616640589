import { useCallback, useEffect, useRef, useState } from 'react'
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds'
import { OpenChatWhenInactiveArgs, InactiveStatus } from './types'
import useMatchMedia from '../useMatchMedia'
import { Breakpoint } from '../../Breakpoint/Breakpoint'
import useChatController from '../useChatController'

/**
 * Hook para abrir o widget Chat de acordo com o tempo de inatividade do usuário
 * @property {number}   - Tempo de inatividade para abertura do chat
 * @property {string[]} activityEvents - Eventos que resetam cronômetro de inatividade
 */
export const useOpenChatWhenInactive = ({
  inactivityTime = 10,
  activityEvents = ['click', 'touchstart']
}: OpenChatWhenInactiveArgs) => {
  const { match: isMobile } = useMatchMedia(
    `(max-width: ${Breakpoint.SmallDesktop})`
  )
  const { startChangeWidgetListener } = useChatController()

  const [chatIdleStatus, setChatIdleStatus] = useState<InactiveStatus>('active')

  const innerTime = useRef({
    inactivityTime: (inactivityTime * 1000) / 2
  })

  const innerStatus = useRef<InactiveStatus>('active')

  const timeInterval = useRef(0)
  const startTime = useRef(new Date())
  const elapsedTime = useRef(new Date())

  /**
   * Reinicia o cronômetro
   */
  const resetTimer = useCallback(() => {
    startTime.current = new Date()
  }, [])

  /**
   * Limpa o interval quando o componente é desmontado
   */
  const stopTimer = useCallback(() => {
    innerStatus.current = 'active'
    setChatIdleStatus('active')

    window.clearInterval(timeInterval.current)
  }, [])

  /**
   * Dispara uma função quando o chat é aberto
   */
  const onOpenChat = useCallback((statusChat: boolean) => {
    if (statusChat) {
      stopTimer()
    }
  }, [])

  /**
   * Abre o widget Chat
   */
  const openChat = useCallback(() => {
    if (!isMobile) {
      window?._handleOpenNice()
    }
  }, [isMobile])

  /**
   * Abre o widget Chat ao atingir o tempo de inatividade estipulado
   */
  const openChatWhenInactive = useCallback(() => {
    if (!elapsedTime.current || !startTime.current) return

    const inactive = differenceInMilliseconds(
      elapsedTime.current,
      startTime.current
    )

    if (
      inactive >= innerTime.current.inactivityTime &&
      innerStatus.current === 'active'
    ) {
      resetTimer()
      innerStatus.current = 'delayed'
      setChatIdleStatus('delayed')

      return
    }

    if (
      inactive >= innerTime.current.inactivityTime &&
      innerStatus.current === 'delayed'
    ) {
      stopTimer()

      innerStatus.current = 'idle'
      setChatIdleStatus('idle')

      openChat()
    }
  }, [stopTimer, resetTimer, openChat])

  /**
   * Inicia o listener do evento
   */
  const startListener = useCallback(() => {
    activityEvents.forEach(event => {
      document.addEventListener(event, resetTimer, true)
    })
  }, [resetTimer, activityEvents])

  /**
   * Inicia listener do evento open do Chat
   */
  /**
   * Inicia o cronômetro
   */
  const startTimer = useCallback(() => {
    startListener()

    const now = new Date()

    startTime.current = now
    elapsedTime.current = now

    timeInterval.current = window.setInterval(() => {
      elapsedTime.current = new Date()
      openChatWhenInactive()
    }, 1000)
  }, [openChatWhenInactive, startListener])

  const cleanUp = useCallback(() => {
    stopTimer()

    activityEvents.forEach(event => {
      document.removeEventListener(event, resetTimer)
    })
  }, [resetTimer, stopTimer, activityEvents])

  useEffect(() => {
    return cleanUp
  }, [cleanUp])

  useEffect(() => {
    startChangeWidgetListener(onOpenChat)
  }, [startChangeWidgetListener])

  return { startTimer, resetTimer, stopTimer, chatIdleStatus }
}
