import { Local, LocalAlgoliaHit } from 'models/Local'
import { VerificarCobertura } from 'models/MixPanel'
import {
  HospitalSuggestionByCoordinatesParams,
  SugestaoDeUnidadesElegibilidade,
  UnidadeAlgolia,
  UnidadeEndereco
} from 'models/Unidade'
import { FallbackName } from 'pages/Consulta/FallBacks'
import { ValidacaoElegibilidade } from './elegibilidadeHelper'
import { gerarLinkDoMaps } from './googleMapsHelper'
import UnidadeService from 'services/unidadeService'

export const obtemEnderecoUnidade = (
  enderecoUnidade: UnidadeEndereco,
  exibirComplemento = true
): string => {
  if (!enderecoUnidade) {
    return ''
  }

  const { logradouro, complemento, numero, bairro, municipio, uf } =
    enderecoUnidade

  const endereco = {
    _logradouro: logradouro,
    _numero: numero,
    _complemento: complemento,
    _bairro: bairro,
    _municipio: municipio,
    _uf: uf,
    get logradouro() {
      return this._logradouro ? this._logradouro : ''
    },
    get numero() {
      return this._numero ? `, ${this._numero}` : ''
    },
    get complemento() {
      return this._complemento && exibirComplemento
        ? `, ${this._complemento}`
        : ''
    },
    get bairro() {
      return this._bairro ? ` - ${this._bairro}` : ''
    },
    get municipio() {
      return this._municipio ? `, ${this._municipio}` : ''
    },
    get uf() {
      return this._uf ? ` - ${this._uf}` : ''
    }
  }

  return `${endereco.logradouro}${endereco.numero}${endereco.complemento}${endereco.bairro}${endereco.municipio}${endereco.uf}`
}

export const obtemLinkDoGoogleMaps = (endereco: UnidadeEndereco): string => {
  const fullAddress = `${obtemEnderecoUnidade(
    endereco,
    false
  )} - ${endereco?.cep}`

  return gerarLinkDoMaps(fullAddress)
}

export const obtemUnidadesValidacao = (
  validacoes: ValidacaoElegibilidade[]
) => {
  if (!validacoes || validacoes.length === 0) return []

  const NOME_VALIDACAO: FallbackName =
    'PlanoComAtendimentoParaEspecialidadeNoLocal'
  const validacao = validacoes.find(
    validacao => validacao.validacao === NOME_VALIDACAO
  )

  return validacao?.unidades
}

export const obtemUnidadesPorCobertura = (
  unidades: SugestaoDeUnidadesElegibilidade[] = []
) => {
  if (!unidades || unidades.length === 0) return

  const unidadesPorCobertura: VerificarCobertura = {
    CODIGOS_UNIDADES_COM_COBERTURA: [],
    NOMES_UNIDADES_COM_COBERTURA: [],
    CODIGOS_UNIDADES_SEM_COBERTURA: [],
    NOMES_UNIDADES_SEM_COBERTURA: []
  }

  for (const unidade of unidades) {
    if (unidade.possuiCobertura) {
      unidadesPorCobertura.CODIGOS_UNIDADES_COM_COBERTURA?.push(unidade.id)
      unidadesPorCobertura.NOMES_UNIDADES_COM_COBERTURA?.push(unidade.nome)
    } else if (!unidade.possuiCobertura) {
      unidadesPorCobertura.CODIGOS_UNIDADES_SEM_COBERTURA?.push(unidade.id)
      unidadesPorCobertura.NOMES_UNIDADES_SEM_COBERTURA?.push(unidade.nome)
    }
  }

  return unidadesPorCobertura
}

export const obtemUnidadesElegiveis = (
  unidades: SugestaoDeUnidadesElegibilidade[] = []
) => {
  return unidades.filter(unidade => unidade.possuiCobertura)
}

export const montaQueryAlgoliaUnidades = (
  unidades: Array<Partial<SugestaoDeUnidadesElegibilidade>>
): string => {
  if (!unidades || unidades.length === 0) return ''

  return unidades.map(unidade => `objectID:H${unidade?.idRdsl}`).join(' OR ')
}

export const obtemUnidadesId = (
  unidades: Array<Partial<UnidadeAlgolia & LocalAlgoliaHit>> = []
) => {
  if (!unidades || unidades.length === 0) return []

  const unidadesId = unidades.map(unidade =>
    Number(unidade.id || unidade.idMotion)
  )

  return unidadesId
}

export const buildLocalCoordinatesParam = (local: Local) => {
  const hospitalCoordinatesParam: HospitalSuggestionByCoordinatesParams = {
    id: String(local.id),
    latitude: local.coordenadas.latitude,
    longitude: local.coordenadas.longitude,
    ...(local.raioExpansao
      ? {
          raio: local.raioExpansao
        }
      : {})
  }

  return hospitalCoordinatesParam
}

export const getSuggestionByHospitalCoordinates = async (
  params: HospitalSuggestionByCoordinatesParams = {
    id: '',
    latitude: 0,
    longitude: 0
  }
): Promise<string[]> => {
  if (params.raio === null || params.raio === undefined) {
    return []
  }

  const hospitalSuggestion =
    await UnidadeService.getSuggestionByHospitalCoordinates(params)

  const hospitalSuggestionId = hospitalSuggestion.map(
    suggestion => `H${suggestion.idRdsl}`
  )

  return hospitalSuggestionId
}
