import React from 'react'
import { Icon } from '@rededor/crdsl'
import styled from 'styled-components'

export interface StepIconProps {
  name: string
  color?: string
}

const StepIcon = styled.div`
  width: fit-content;
  padding: 1px;
`

const StepbarIcon = ({ name, color }: StepIconProps) => (
  <StepIcon>
    <Icon name={name} color={color} size="md" />
  </StepIcon>
)

StepbarIcon.defaultProps = {
  color: '#004186'
}

export default StepbarIcon
