import { Local, LocalSugestaoBackend } from 'models/Local'

const buildSuggestion = (suggestion: LocalSugestaoBackend | string) => {
  const param = typeof suggestion === 'string' ? suggestion : suggestion.idLocal

  return param
}

export const buildLocalParam = (
  local?: Local,
  localSuggestions: Array<string | LocalSugestaoBackend> = [],
  expansionSuggestion: string[] = []
) => {
  const hasLocalSuggestion = localSuggestions.length > 0
  const hasExpansionSuggestion = expansionSuggestion.length > 0

  if (!hasLocalSuggestion && !hasExpansionSuggestion) {
    return String(local?.id)
  }

  const localParams = hasLocalSuggestion
    ? localSuggestions.map(buildSuggestion)
    : []

  const selectedLocalId = local?.tipo !== 'Grupo' ? [String(local?.id)] : []

  const expansionParams = hasExpansionSuggestion
    ? [...expansionSuggestion.map(buildSuggestion), ...selectedLocalId]
    : []

  return [...localParams, ...expansionParams]
}
