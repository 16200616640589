import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import {
  LOGAR_USUARIO,
  AcessoLogadoAction,
  acessoLogadoInitialState,
  DESLOGAR_USUARIO,
  SELECIONAR_USUARIO
} from './types'
import { AppState } from '../index'
import { Usuario } from '../../models/Usuario'
import { AcessoLogado } from '../../models/AcessoLogado'
import { obtemStatusDeAcessoLogado } from 'utils/helpers/acessoLogadoHelper'

export const logarUsuario =
  (usuarios: Usuario[]): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    const { usuarios: user } = obtemStatusDeAcessoLogado()
    const titular = usuarios.find(usuario => usuario.titular)

    const logado = titular || usuarios[0]

    const dependentes = logado?.titular
      ? usuarios.filter(usuario => !usuario.titular)
      : []

    const payload = {
      ativo: true,
      usuarios:
        dependentes && logado ? { logado, titular, dependentes } : undefined,
      usuarioSelecionadoParaAgendamento: logado?.titular ? undefined : logado,
      etapaDeEscolhaDeUsuarioConcluida: !logado?.titular
    }

    const data: AcessoLogadoAction = {
      type: LOGAR_USUARIO,
      payload
    }

    if (!user?.logado) {
      dispatch(data)
    }
  }

export const deslogarUsuario =
  (): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    const data: AcessoLogadoAction = {
      type: DESLOGAR_USUARIO,
      payload: acessoLogadoInitialState
    }
    dispatch(data)
  }

export const selecionaUsuarioParaAgendamento =
  (
    payload: AcessoLogado
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    const data: AcessoLogadoAction = {
      type: SELECIONAR_USUARIO,
      payload
    }
    dispatch(data)
  }
