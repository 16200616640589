import { colors } from '@rededor/crdsl/dist/theme/colors'
import { BrLarge } from 'components/BreakLine'
import TextBase from 'components/TextBase'
import { AgendamentoHelper } from 'pages/Consulta/Agendar-Consulta/helpers'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { obtemPeriodoPorExtenso } from 'utils/helpers/horariosHelper'
import { PageLoadingPropTypes } from '../../interface'
import { LoadingMessage } from '../LoadingMessage'

const tituloDoLoader = AgendamentoHelper.obtemTituloDoLoader()
export const HandleContentPageLoading = ({
  type,
  verificandoElegibilidade,
  periodoSendoBuscado
}: PageLoadingPropTypes) => {
  const { t } = useTranslation()
  const [exibirMensagem, setExibirMensagem] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => {
      setExibirMensagem(true)
    }, 1500)
  }, [])

  if (type === 'genericPageLoading') {
    return (
      <>
        {exibirMensagem && (
          <>
            <LoadingMessage
              title={t('Carregando o Agendamento de Consultas.')}
              message={t('Esse carregamento pode demorar um pouco.')}
            />
            <TextBase
              color="#4F4F4F"
              fontSize={13}
              fontSizeSmall={12}
              fontWeight="500"
              display="block"
            >
              {'Por favor, aguarde.'}
            </TextBase>
          </>
        )}
      </>
    )
  }

  if (type === 'finishSchedule') {
    return (
      <>
        {exibirMensagem && (
          <LoadingMessage
            title={t('Confirmando seu agendamento')}
            message={t('Por favor, aguarde a confirmação.')}
          />
        )}
      </>
    )
  }

  if (type === 'badGateway') {
    return (
      <>
        {exibirMensagem && (
          <LoadingMessage
            title={t('Por favor, aguarde um instante.')}
            message={t('Estamos processando seu agendamento.')}
          />
        )}
      </>
    )
  }

  if (type === 'schedulingLoading') {
    return (
      <>
        <TextBase
          fontSize={18}
          fontSizeSmall={16}
          fontWeight={verificandoElegibilidade ? '500' : '300'}
          fontWeightSmall={verificandoElegibilidade ? '500' : '400'}
          color={`${colors.primary}`}
          padding="15px 0 0"
          textAlign="center"
          lineHeight={145}
        >
          {verificandoElegibilidade
            ? t('Verificando elegibilidade dos dados preenchidos ')
            : tituloDoLoader && `${tituloDoLoader} `}

          <TextBase fontSize={18} fontSizeSmall={16} fontWeight="500">
            {`${AgendamentoHelper.obtemTextoDoLoader()}`}
          </TextBase>

          {periodoSendoBuscado && (
            <>
              <BrLarge />
              {t(' no período de')}{' '}
              <TextBase
                fontSize={18}
                fontSizeSmall={16}
                fontWeight="500"
                padding="0"
              >
                {obtemPeriodoPorExtenso(periodoSendoBuscado)}
              </TextBase>
              {'.'}
            </>
          )}
        </TextBase>
        {exibirMensagem && (
          <LoadingMessage
            colorMessage="#4F4F4F"
            message={t('Este carregamento pode demorar um pouco')}
          />
        )}
      </>
    )
  }

  if (type === 'telemedicineAppointmentLoading') {
    return (
      <>
        <TextBase
          fontSize={18}
          fontSizeSmall={16}
          fontWeight="500"
          color="#004186"
          padding="15px 0 0"
          textAlign="center"
          lineHeight={145}
        >
          Localizando a sala de atendimento virtual
        </TextBase>
        <LoadingMessage message={'Em breve você será redirecionado.'} />
      </>
    )
  }

  return <></>
}
