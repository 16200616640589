import { format as formatDateFns, parseISO, getMinutes, format } from 'date-fns'
import locale from 'date-fns/locale/pt-BR'
import localeUSA from 'date-fns/locale/en-US'

import { capitalize } from './textHelper'
import { toBrTimeZone } from './horariosHelper'

class DataHelper {
  private static readonly converteParaDate = (data: string): Date => {
    const convertedDate = toBrTimeZone(data).toISOString()

    return parseISO(convertedDate)
  }

  public static formatar = (data: Date | string, formato: string): string => {
    const dataASerFormatada: Date =
      data instanceof Date ? data : DataHelper.converteParaDate(data)

    return formatDateFns(dataASerFormatada, formato, { locale })
  }

  public static getDateToPTBR = (date: string): string => {
    const parsedDate = parseISO(date)
    const dateFormatted = format(parsedDate, 'dd-MM-yyyy')

    return dateFormatted
  }

  public static obtemDataEmPTBR = (data: Date | string): string => {
    const dataASerFormatada: Date =
      data instanceof Date ? data : DataHelper.converteParaDate(data)

    return formatDateFns(dataASerFormatada, 'yyyy-MM-dd', { locale })
  }

  public static obtemDataEmUSA = (data: Date | string): string => {
    const dataASerFormatada: Date =
      data instanceof Date ? data : DataHelper.converteParaDate(data)

    return formatDateFns(dataASerFormatada, 'MMM-dd-yyyy', {
      locale: localeUSA
    })
  }

  private static obtemDia(data: Date | string) {
    return DataHelper.formatar(data, 'dd')
  }

  public static obtemTurno = (
    data: string,
    nomeDoTurnoHumanizado = true
  ): string => {
    const horas = Number(
      DataHelper.formatar(DataHelper.converteParaDate(data), 'HH')
    )
    const minutos = Number(
      DataHelper.formatar(DataHelper.converteParaDate(data), 'mm')
    )

    if ((horas >= 0 && horas < 12) || (horas === 12 && minutos <= 30)) {
      return nomeDoTurnoHumanizado ? 'Manhã' : 'manha'
    }

    if ((horas >= 12 && horas < 18) || (horas === 18 && minutos <= 30)) {
      return nomeDoTurnoHumanizado ? 'Tarde' : 'tarde'
    }

    return nomeDoTurnoHumanizado ? 'Noite' : 'noite'
  }

  public static obtemHora = (data: string): string => {
    let formatoData = `k'h'mm`
    const tmpData = DataHelper.converteParaDate(data)

    if (getMinutes(tmpData) === 0) {
      formatoData = `H'h'`
    }

    return `${DataHelper.formatar(data, formatoData)}`
  }

  public static obtemHoraETurno = (data: string): string => {
    return `${DataHelper.obtemHora(data)} - ${DataHelper.obtemTurno(data)}`
  }

  public static obtemDataResumida = (data: string): string => {
    return `${DataHelper.formatar(data, 'dd/MM')} - ${DataHelper.formatar(
      data,
      'H:mm'
    )}h`
  }

  public static obtemDiaDaSemanaEMes = (data: string): string => {
    return `${capitalize(
      DataHelper.formatar(data, 'cccc')
    )}, ${DataHelper.obtemDia(data)} de ${DataHelper.formatar(data, 'LLLL')}`
  }

  public static obtemMesEAno = (data: string): string => {
    return `${DataHelper.obtemDia(data)} de ${DataHelper.formatar(
      data,
      'LLLL'
    )}, ${DataHelper.formatar(data, 'yyyy')}`
  }
}

export default DataHelper
