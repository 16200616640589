import { Icon } from '@rededor/crdsl'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { RoutesApp } from './routes/routesApp'
import { AppDispatch } from './store'
import StepIcon from './components/StepBar/StepIcons/index'
import ProgressIcon from './components/StepBar/progressIcons/index'
import { handlerStartStepBar } from './store/stepbar/actions'
import { StepBarAction } from './store/stepbar/types'
import HelmetHelper from './utils/helpers/helmetHelper'
import MixpanelHelper from 'utils/helpers/mixpanelHelper'
import MixPanelEventos from 'shared/consts/mixpanel/eventos'
import { obtemNomeDaPaginaAtual } from 'utils/helpers/stepBarHelpers/stepbarHelpers'
import { history } from 'utils/browserhistory'
import { BannerLGPDInitialStyle } from './styles'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import chavesRecaptcha from './shared/consts/chavesRecaptcha'
import { ReservationTimerProvider } from 'shared/Contexts/ReservationTimerContext'
import Chat from './components/Chat'
import ButtonChatDefault from './components/ButtonChatDefault'
import { useOpenChatWhenInactiveContext } from './shared/Contexts/OpenChatWhenInactive'
import { isMobile } from 'react-device-detect'
export interface IconProps {
  name?: string
  size?: number
}

const DivContainerStepBar = styled.div`
  @media screen and (max-width: 62rem) {
    padding-bottom: 60px;
  }
`

function App(): JSX.Element {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()

  const { chatIdleStatus } = useOpenChatWhenInactiveContext()

  localStorage.setItem('AP_CURRENT_LOCATION', '/paciente/marcar-consulta/')

  // alteraTokenDeAcessoLogadoEmAmbienteDev(
  //   'b76de336-eac2-48e2-a74a-786d14672bd4',
  //   '1625842855257'
  // )

  const arrayURL = history.location.pathname.split('/').length
  const stepAtual = history.location.pathname.split('/')[arrayURL - 1]
  const steps = ['historico', 'escolha-usuario']

  const canDispatchEvent = (stepAtual: string, proximoStep: string[]) => {
    return !proximoStep.includes(stepAtual)
  }

  useEffect(() => {
    if (canDispatchEvent(stepAtual, steps)) {
      MixpanelHelper.sendEventGeneric(MixPanelEventos.SESSION_START, {
        ETAPA: obtemNomeDaPaginaAtual()
      })
    }
  }, [])

  window.addEventListener('beforeunload', function (e) {
    if (canDispatchEvent(stepAtual, steps)) {
      e.stopImmediatePropagation()
      MixpanelHelper.sendEventGeneric(MixPanelEventos.SESSION_END, {
        ETAPA: obtemNomeDaPaginaAtual()
      })
    }
  })

  useEffect(() => {
    const stepBarConfig: StepBarAction[] = [
      {
        id: 1,
        name: 'especialidade-medico',
        titulo: t('Escolha Medico Especialidade'),
        icon: <StepIcon name="especialidadeGenerica" color="#BDBDBD" />,
        iconName: 'especialidadeGenerica',
        iconMobile: <Icon name="especialidadeGenerica" color="#BDBDBD" />,
        iconStats: <ProgressIcon type="initial" />,
        textColor: '#BDBDBD',
        preventClick: false,
        action: () => {
          // console.log('disparando ação especialidade/medico')
        }
      },
      {
        id: 2,
        name: 'local',
        titulo: t('Local'),
        icon: <StepIcon name="aGMUnidade" color="#BDBDBD" />,
        iconName: 'aGMUnidade',
        iconMobile: <Icon name="aGMUnidade" color="#BDBDBD" />,
        iconStats: <ProgressIcon type="initial" />,
        textColor: '#BDBDBD',
        preventClick: false,
        action: () => {
          // console.log('disparando ação local')
        }
      },
      {
        id: 3,
        name: 'paciente',
        titulo: t('Paciente'),
        icon: <StepIcon name="aGMPaciente" color="#BDBDBD" />,
        iconName: 'aGMPaciente',
        iconMobile: <Icon name="aGMPaciente" color="#BDBDBD" />,
        iconStats: <ProgressIcon type="initial" />,
        textColor: '#BDBDBD',
        preventClick: false,
        action: () => {
          // console.log('disparando ação paciente')
        }
      },
      {
        id: 4,
        name: 'pagamento',
        titulo: t('Plano'),
        icon: <StepIcon name="plano" color="#BDBDBD" />,
        iconName: 'plano',
        iconMobile: <Icon name="plano" color="#BDBDBD" />,
        iconStats: <ProgressIcon type="initial" />,
        textColor: '#BDBDBD',
        preventClick: false,
        action: () => {
          // console.log('disparando ação plano')
        }
      },
      {
        id: 5,
        name: 'agendar-consulta',
        titulo: t('Agende'),
        icon: <StepIcon name="aGMAgendar" color="#BDBDBD" />,
        iconName: 'aGMAgendar',
        iconMobile: <Icon name="aGMAgendar" color="#BDBDBD" />,
        iconStats: <ProgressIcon type="initial" />,
        textColor: '#BDBDBD',
        preventClick: false,
        action: () => {
          // console.log('disparando ação agende')
        }
      }
    ]
    dispatch(
      handlerStartStepBar({
        step: 1,
        title: t('AgDeEx'),
        stepConfig: stepBarConfig
      })
    )
  }, [t, dispatch])

  return (
    <>
      <BannerLGPDInitialStyle />
      {HelmetHelper.incorporaScriptsDoLgpd()}
      {HelmetHelper.adicionaTituloDaPagina()}
      {HelmetHelper.injectScriptsGoogleOptimize()}
      {HelmetHelper.addMobileWebAppTitle()}
      <DivContainerStepBar>
        <GoogleReCaptchaProvider
          container={{
            element: 'google-recaptcha',
            parameters: {
              badge: 'bottomleft'
            }
          }}
          reCaptchaKey={chavesRecaptcha?.site || ''}
        >
          <ReservationTimerProvider>
            <RoutesApp />
          </ReservationTimerProvider>
        </GoogleReCaptchaProvider>

        <Chat>
          <ButtonChatDefault
            animate={chatIdleStatus === 'delayed'}
            visible={!isMobile || chatIdleStatus === 'idle'}
            dataQaId="Button_NiceChat"
          />
        </Chat>
      </DivContainerStepBar>
    </>
  )
}

export default App
