export const INITIAL_STATE = {
  filter: {
    unidades: [],
    turno: [],
    genero: [],
    dataPreferencia: '',
    horarioEncaixe: [],
    diasPreferencia: []
  },
  badgeNumber: {
    turno: 0,
    genero: 0,
    unidades: 0,
    dataPreferencia: 0,
    horarioEncaixe: 0,
    diasPreferencia: 0
  },
  filterSelected: {}
}
