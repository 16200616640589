import styled, { css, keyframes } from 'styled-components'

const pulseAnimation = keyframes`
  10% { transform: scale(1.1); }
  20% { transform: scale(1.12); }
  30% { transform: scale(1); }
  40% { transform: scale(1.1); }
  50% { transform: scale(1.12); }
  0%, 100% { transform: scale(1); }
`
export const WrapperPageLoading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  background: rgba(255, 255, 255, 0.95);

  position: fixed;
  top: 0;
  left: 0;

  z-index: 99;
`

export const WrapperLoaderIcon = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${theme?.colors?.white};
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.16);
    padding: 30px;

    border-radius: 50%;

    animation: ${pulseAnimation} 1.3s cubic-bezier(0.175, 0.885, 0.32, 2)
      infinite;

    @media (max-width: '768px') {
      padding: 15px;
    }
  `}
`

export const WrapperLoaderContent = styled.div`
  margin-top: 30px;
  text-align: center;
`

export const SecondaryTextLoader = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme?.fontWeights?.regular};
    font-size: ${theme?.fontSizes?.sm};

    @media (max-width: '768px') {
      font-size: ${theme?.fontSizes?.xs};
    }
  `}
`

export const WrapperExtraContent = styled.div`
  ${({ theme }) => css`
    max-width: 500px;
    padding: 0 10px;
    margin-bottom: 10px;

    font-size: ${theme?.fontSizes.md};
  `}
`
