import { createContext } from 'react'
import { ReservationTimerContextValue } from './types'

export const reservationTimerContext =
  createContext<ReservationTimerContextValue>({
    getSecondsToExpiration: () => 0,
    titleReservationTimer: '',
    hasReservationTimerExpired: false,
    hasReservationTimerError: false,
    initTimer: () => false,
    resetTimer: () => false,
    showModalReservationExpired: false,
    stopTimer: () => false,
    stopTimerOnErrorReservation: () => false
  })
