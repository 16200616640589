import React from 'react'

import Loading from 'components/Loading'
import { HandleContentPageLoading } from './components/HandleContentPageLoading'
import { PageLoadingPropTypes } from './interface'
import {
  WrapperLoaderContent,
  WrapperLoaderIcon,
  WrapperPageLoading
} from './styles'

export const PageLoading = ({
  type,
  periodoSendoBuscado,
  verificandoElegibilidade
}: PageLoadingPropTypes) => (
  <WrapperPageLoading data-testid="page_loading">
    <WrapperLoaderIcon>
      <Loading customSizeInPx="60" />
    </WrapperLoaderIcon>
    <WrapperLoaderContent>
      <HandleContentPageLoading
        type={type}
        periodoSendoBuscado={periodoSendoBuscado}
        verificandoElegibilidade={verificandoElegibilidade}
      />
    </WrapperLoaderContent>
  </WrapperPageLoading>
)
