// Describing the different ACTION NAMES available
export const START_STEP = 'START_STEP'
export const CHANGE_STEP = 'CHANGE_STEP'
export const CHANGE_STEP_STATUS = 'CHANGE_STEP_STATUS'
export const CHANGE_MULTI_STEP_STATUS = 'CHANGE_MULTI_STEP_STATUS'

// Describing the shape of the stepbar slice of state

export interface StepBar {
  step: number
  title: string
  stepConfig: StepBarAction[]
}

export type StepStatus =
  | 'initial'
  | 'finished'
  | 'warning'
  | 'actual'
  | 'selected'

export type StepName =
  | 'especialidade-medico'
  | 'local'
  | 'paciente'
  | 'pagamento'
  | 'agendar-consulta'

export interface StepBarAction {
  id: number
  name: StepName
  colorBorder?: string
  titulo: string
  hasToolTip?: JSX.Element
  textColor?: string
  status?: StepStatus
  iconName: string
  icon: JSX.Element
  iconStats: JSX.Element
  iconMobile?: JSX.Element
  dropdown?: JSX.Element
  action: () => unknown
  preventClick?: boolean
  preventShow?: boolean
  dadosPreenchidosStep?: string
}

export interface StepBarActionUpdateStatus {
  id?: number
  name?: string
  colorBorder?: string
  titulo?: string
  hasToolTip?: JSX.Element
  textColor?: string
  status?: StepStatus
  icon?: JSX.Element
  iconStats?: JSX.Element
  iconMobile?: JSX.Element
  dropdown?: JSX.Element
  action?: () => unknown
  preventClick?: boolean
  preventShow?: boolean
  dadosPreenchidosStep?: string
}

export interface StepBarState {
  stepbar: StepBar
}

interface StartStepBarAction {
  type: typeof START_STEP
  payload: StepBar
}

export interface ChangeStatusStepBar {
  id?: number
  name?: string
  stepbarTarget?: boolean
  stepSelected: StepBarActionUpdateStatus
}

export interface ChangeStatusStepBarMulti {
  config: ChangeStatusStepBar[]
}

interface ChangeMultiStepBarStatusAction {
  type: typeof CHANGE_MULTI_STEP_STATUS
  meta: ChangeStatusStepBarMulti
}

export type StepBarActionTypes =
  | StartStepBarAction
  | ChangeMultiStepBarStatusAction
