import { ATUALIZA_BANNER, BannerAction } from './types'

const initialState: any = {}

export function bannerReducer(state = initialState, action: BannerAction): any {
  switch (action.type) {
    case ATUALIZA_BANNER:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}
