import React from 'react'
import { NiceChat } from '@rededor/nice-chat-component-lib-react'
import { isMobile } from 'react-device-detect'

import { obtemStatusDeAcessoLogado } from 'utils/helpers/acessoLogadoHelper'
import { ChatButtonProps } from './interfaces'

const Chat = ({ children }: ChatButtonProps) => {
  const token = process.env.REACT_APP_NICECHAT_TOKEN
  const { usuarios } = obtemStatusDeAcessoLogado()
  const userName = usuarios?.logado.nome

  return (
    <>
      <NiceChat
        userName={userName}
        hasFirstAutomatedMessageContent="Olá"
        isSendFirstMessageAutomatically
        isLazy={false}
        style={{ width: '100%' }}
        isMobile={isMobile}
        token={token}
      >
        {children}
      </NiceChat>
    </>
  )
}
export default Chat
