import { useCallback } from 'react'

/**
 * Hook utilitários do Chat
 * @function(startOpenWidgetListener) -> Verifica se o Chat está aberto ou fechado. Se o Chat estiver aberto ele executa o callback, se estiver fechado ele não faz nada.
 */
export const useChatController = () => {
  const startChangeWidgetListener = useCallback(
    (callback: (evtDetail: boolean) => unknown) => {
      const niceChat = document.querySelector('nice-chat')

      if (niceChat) {
        niceChat.addEventListener('widgetChange', event => {
          const { detail: isChatActive } = event as CustomEvent
          callback(isChatActive)
        })
      }
    },
    []
  )

  return { startChangeWidgetListener }
}
