import { addSeconds, differenceInSeconds } from 'date-fns'
import { ReservationTimerContextValue } from '../types'
import {
  RESERVATION_EXPIRATION_LIMIT_IN_SECONDS,
  STEPS_TO_SHOW_MODAL
} from './settings'
import { settings } from '../../../../components/ModalReservationExpired/settings'
import { useMemo, useRef, useState } from 'react'
import { useLocationStep } from 'shared/Hooks/useLocationStep'
const { TITLE_ERROR, TITLE_RESERVATION } = settings

export const useReservationTimerProvider = (): ReservationTimerContextValue => {
  const { getCurrentLocationStep } = useLocationStep()
  const [hasReservationTimerExpired, setHasReservationTimerExpired] =
    useState(false)
  const [hasReservationTimerError, setHasReservationTimerError] =
    useState(false)

  const showModalReservationExpired = useMemo(() => {
    const currentLocationStep = getCurrentLocationStep()
    const isAllowedStep = STEPS_TO_SHOW_MODAL.includes(currentLocationStep)

    return hasReservationTimerExpired && isAllowedStep
  }, [getCurrentLocationStep, hasReservationTimerExpired])

  const reservationExpirationTimeRef = useRef<Date | null>(null)
  const timeIntervalRef = useRef(0)

  const stopTimer = () => {
    clearInterval(timeIntervalRef.current)
    reservationExpirationTimeRef.current = null
  }

  const hasTimeExpired = () => {
    if (!reservationExpirationTimeRef.current) {
      return
    }

    const timeToExpiration = differenceInSeconds(
      reservationExpirationTimeRef.current,
      new Date()
    )

    return timeToExpiration <= 0
  }

  const checkReservationTimerExpiration = () => {
    if (hasTimeExpired()) {
      setHasReservationTimerExpired(true)
      stopTimer()
    }
  }

  const initTimer = () => {
    setHasReservationTimerExpired(false)
    setHasReservationTimerError(false)

    reservationExpirationTimeRef.current = addSeconds(
      new Date(),
      RESERVATION_EXPIRATION_LIMIT_IN_SECONDS
    )

    timeIntervalRef.current = window.setInterval(
      checkReservationTimerExpiration,
      500
    )
  }

  const getSecondsToExpiration = () => {
    if (!reservationExpirationTimeRef.current) {
      return 0
    }

    return differenceInSeconds(reservationExpirationTimeRef.current, new Date())
  }

  const resetTimer = () => {
    clearInterval(timeIntervalRef.current)
    initTimer()
  }

  const titleReservationTimer = hasReservationTimerError
    ? TITLE_ERROR
    : TITLE_RESERVATION

  const stopTimerOnErrorReservation = () => {
    stopTimer()
    setHasReservationTimerError(true)
  }

  return {
    titleReservationTimer,
    hasReservationTimerError,
    stopTimerOnErrorReservation,
    getSecondsToExpiration,
    hasReservationTimerExpired,
    initTimer,
    resetTimer,
    showModalReservationExpired,
    stopTimer
  }
}
