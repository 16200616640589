import { AxiosError } from 'axios'
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'

import { AppState } from '../index'
import {
  ARMAZENAR_ERRO_GENERICO,
  CLEAR_STORED_ERROR,
  SET_ERROR_BAD_GATEWAY
} from './types'

export const armazenarErroGenericoBackEnd =
  (error: AxiosError): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: ARMAZENAR_ERRO_GENERICO,
      payload: error
    })
  }

export const setRetryBadGateway =
  (
    errorBadGateway: boolean
  ): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: SET_ERROR_BAD_GATEWAY,
      payload: errorBadGateway
    })
  }

export const clearStoredError =
  (): ThunkAction<void, AppState, unknown, Action<string>> =>
  async dispatch => {
    dispatch({
      type: CLEAR_STORED_ERROR
    })
  }
