import { AcessoLogado } from '../../models/AcessoLogado'
import UsuarioService from '../../services/usuarioService'
import { store } from '../../store'
import { deslogarUsuario, logarUsuario } from '../../store/acessoLogado/actions'
import { atualizaPaciente } from '../../store/paciente/actions'
import GoogleTagManagerHelper from './googleTagManagerHelper'

const chavesNoStorage = {
  token: 'SSO_TOKEN_SESSION',
  validadeToken: 'SSO_TOKEN_VALID_UNTIL'
}

export const removeTokenDeAcessoLogado = (): void => {
  localStorage.removeItem(chavesNoStorage.token)
  localStorage.removeItem(chavesNoStorage.validadeToken)
}

export const obtemTokenDeAcessoLogado = (): string | undefined => {
  const token = localStorage.getItem(chavesNoStorage.token)

  return token && token !== '' && token !== null ? token : undefined
}

export const recuperaDadosDoAcessoLogado = async (): Promise<void> => {
  const token = obtemTokenDeAcessoLogado()
  const { dispatch } = store

  if (token) {
    const usuariosRecuperados =
      await UsuarioService.obtemInformacoesPeloToken(token)

    if (usuariosRecuperados) {
      dispatch(logarUsuario(usuariosRecuperados))

      const {
        etapaDeEscolhaDeUsuarioConcluida,
        usuarioSelecionadoParaAgendamento,
        usuarios
      } = obtemStatusDeAcessoLogado()

      if (usuarios?.logado) {
        GoogleTagManagerHelper.disparaEventoGtmDeAcessoLogado()
      }

      if (
        etapaDeEscolhaDeUsuarioConcluida &&
        usuarioSelecionadoParaAgendamento
      ) {
        atualizaPacienteParaAgendamento()
      }

      return
    }
  }

  dispatch(deslogarUsuario())
}

/* O TESTE DESTE MÉTODO SERÁ IGNORADO PELO COMENTÁRIO ABAIXO */
/* ESTE É UM MÉTODO APENAS PARA EFEITOS EM AMBIENTE DEV */
/* istanbul ignore next */
export const alteraTokenDeAcessoLogadoEmAmbienteDev = (
  novoToken: string,
  validade: string
): void => {
  const nodeEnv = process.env.REACT_APP_NODE_ENV

  if (nodeEnv !== 'production') {
    localStorage.setItem(chavesNoStorage.token, novoToken)
    localStorage.setItem(chavesNoStorage.validadeToken, validade)
  }
}

export const obtemStatusDeAcessoLogado = (): AcessoLogado => {
  return store.getState().acessoLogado
}

export const acessoLogadoEstaAtivo = (): boolean => {
  const { ativo, usuarios } = obtemStatusDeAcessoLogado()

  return ativo && typeof usuarios !== 'undefined'
}

export const escolhaDePacienteDoAcessoLogado = ():
  | 'usuarioSelecionado'
  | 'outroPaciente'
  | undefined => {
  const {
    ativo,
    usuarioSelecionadoParaAgendamento,
    etapaDeEscolhaDeUsuarioConcluida
  } = obtemStatusDeAcessoLogado()

  if (ativo && etapaDeEscolhaDeUsuarioConcluida) {
    if (usuarioSelecionadoParaAgendamento) {
      return 'usuarioSelecionado'
    }

    return 'outroPaciente'
  }

  return undefined
}

export const pacienteEhTitular = (): boolean => {
  return escolhaDePacienteDoAcessoLogado() === 'usuarioSelecionado'
}

export const etapaDeEscolhaDeUsuarioFoiConcluida = (): boolean => {
  return typeof escolhaDePacienteDoAcessoLogado() !== 'undefined'
}

export const atualizaPacienteParaAgendamento = (): void => {
  const { dispatch } = store
  const { usuarios, usuarioSelecionadoParaAgendamento } =
    obtemStatusDeAcessoLogado()
  if (usuarios && usuarioSelecionadoParaAgendamento) {
    dispatch(
      atualizaPaciente({
        apelido: usuarioSelecionadoParaAgendamento.apelido || '',
        cpf: usuarioSelecionadoParaAgendamento.cpf,
        nome: usuarioSelecionadoParaAgendamento.nome,
        email: (usuarios.titular || usuarioSelecionadoParaAgendamento).email,
        genero: usuarioSelecionadoParaAgendamento.genero,
        telefone: (usuarios.titular || usuarioSelecionadoParaAgendamento)
          .telefone,
        dataNascimento: usuarioSelecionadoParaAgendamento.dataNascimento
      })
    )

    GoogleTagManagerHelper.disparaEventoGtmDeNascimentoEGeneroPreenchidos()
  }
}
