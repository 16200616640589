import styled from 'styled-components'

export const BrSmall = styled.br`
  display: none;
  @media screen and (max-width: 62rem) {
    display: block;
  }
`

export const BrLarge = styled.br`
  display: none;
  @media screen and (min-width: 62rem) {
    display: block;
  }
`
