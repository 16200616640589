export const DISPARA_EXCECAO = 'DISPARA_EXCECAO'

export interface ExcecaoAction {
  type: string
  payload: Excecao
}

export interface Excecao {
  codigo: number
  mensagem: string
  fallback?: string
}
