import { TipoPagamento } from 'models/Pagamento'
import TagManager, { DataLayerArgs } from 'react-gtm-module'
import { store } from 'store'
import tagManagerConfig from '../../shared/consts/googleTagManager'
import * as Model from '../../models/EventoGtm'
import { ModalidadeAtendimento } from '../../models/ModalidadeAtendimento'
import { EventsGTM } from '../enums/EventsGTM'
import {
  EventoFiltroListagem,
  EventSchedulingAlert,
  FilterEvent
} from 'shared/Types/Filter'
class GoogleTagManagerHelper {
  public static inicializaGTM(): void {
    TagManager.initialize({
      gtmId: tagManagerConfig.gtmId
    })
  }

  private static disparaEventoGtm<T>(event: T): void {
    const dataLayer: DataLayerArgs = {
      dataLayer: {
        ...(event as unknown as object),
        is_pwa: Boolean(window.matchMedia('(display-mode: standalone)').matches)
      }
    }

    TagManager.dataLayer(dataLayer)
  }

  private static buildDataVirtualPageView(): Model.EventoGtmDePaginaCarregada {
    const { acessoLogado, consulta } = store.getState()

    return {
      event: EventsGTM.VISUALIZACAO_PAGINA,
      userId: acessoLogado?.ativo ? acessoLogado?.usuarios?.logado.id : '',
      unidadem: consulta.unidade ? String(consulta.unidade.id) : ''
    }
  }

  private static buildGtmParametros(params = '') {
    type Parametros = Record<string, string>

    if (params === '') {
      return
    }

    const MAPA_DE_PARAMETROS: Parametros = {
      utm_id: 'campaignID',
      utm_source: 'campaignSource',
      utm_medium: 'campaignMedium',
      utm_campaign: 'campaignName',
      utm_term: 'campaignTerm',
      utm_content: 'campaignContent',
      utm_keyword: 'campaignKeyword',
      idCommunication: 'idCommunication'
    }

    const parametrosList = new URLSearchParams(params)

    const parametros: Parametros = {}

    parametrosList.forEach((value, key) => {
      if (key in MAPA_DE_PARAMETROS) {
        parametros[MAPA_DE_PARAMETROS[key]] = value
      }
    })

    return parametros
  }

  public static disparaEventoGtmDePaginaCarregada = (
    { pathname, search }: Location | URL = window.location,
    nomeFallback = ''
  ): void => {
    const propriedadeFallback = nomeFallback
      ? { fallbackName: nomeFallback }
      : null

    GoogleTagManagerHelper.disparaEventoGtm<
      Model.EventoGtmDePaginaCarregada & Model.EventoGtmPrimeiroAcesso
    >({
      ...GoogleTagManagerHelper.buildDataVirtualPageView(),
      virtualPagePath: pathname,
      ...GoogleTagManagerHelper.buildGtmParametros(search),
      ...propriedadeFallback
    })
  }

  public static disparaEventoGtmDeModalidadeDeAtendimentoSelecionada({
    modalidade,
    doctor = null,
    expertise
  }: {
    modalidade: ModalidadeAtendimento
    doctor: string | null
    expertise: string
  }): void {
    GoogleTagManagerHelper.disparaEventoGtm<Model.EventoGtmDeModalidadeDeAtendimentoSelecionada>(
      {
        event: EventsGTM.MODALIDADE_CONSULTA,
        option: modalidade,
        doctor,
        expertise
      }
    )
  }

  public static disparaEventoGtmDeLocalSelecionado = (local: string): void => {
    GoogleTagManagerHelper.disparaEventoGtm<Model.EventoGtmDeLocalSelecionado>({
      event: EventsGTM.SELECAO_LOCAL,
      local
    })
  }

  public static disparaEventoGtmDeNascimentoEGeneroPreenchidos = (): void => {
    GoogleTagManagerHelper.disparaEventoGtm({
      event: EventsGTM.DADOS_PARCIAIS_PACIENTE
    })
  }

  public static disparaEventoGtmDePagamentoPreenchido = (
    tipo: TipoPagamento
  ): void => {
    GoogleTagManagerHelper.disparaEventoGtm<Model.EventoGtmDePagamentoPreenchido>(
      {
        event: EventsGTM.SELECAO_PAGAMENTO,
        paymentType: tipo
      }
    )
  }

  public static disparaEventoGtmDePagamentoPorPlano = (
    tipo: TipoPagamento,
    convenio: string | undefined,
    plano: string | undefined
  ): void => {
    GoogleTagManagerHelper.disparaEventoGtm<Model.EventoGtmDePagamentoPorPlano>(
      {
        event: EventsGTM.SELECAO_CONVENIO,
        type: tipo,
        medicalInsurance: convenio,
        plan: plano
      }
    )
  }

  public static disparaEventoGtmDeCarregamentoHorarios = (): void => {
    GoogleTagManagerHelper.disparaEventoGtm({
      event: EventsGTM.CARREGANDO_HORARIOS
    })
  }

  public static disparaEventoGtmDeHorariosCarregados = (): void => {
    GoogleTagManagerHelper.disparaEventoGtm({
      event: EventsGTM.CARREGADO_HORARIOS
    })
  }

  public static disparaEventoGtmDeHorarioReservado = (
    horarioReservado: string,
    diaSelecionado: string
  ): void => {
    GoogleTagManagerHelper.disparaEventoGtm<Model.EventoGtmDeHorarioReservado>({
      event: EventsGTM.SELECAO_HORARIO,
      schedule: horarioReservado,
      date: diaSelecionado
    })
  }

  public static disparaEventoGtmDeEscolhasRevisadas = (): void => {
    GoogleTagManagerHelper.disparaEventoGtm({
      event: EventsGTM.CONFIRMA_REVISAO
    })
  }

  public static disparaEventoGtmDeHorarioAgendado = (
    codigoDeRequisicao: string
  ): void => {
    GoogleTagManagerHelper.disparaEventoGtm({
      event: EventsGTM.CONFIRMA_AGENDAMENTO,
      requisitionCode: codigoDeRequisicao
    })
  }

  public static disparaEventoGtmDeAcessoLogado = (): void => {
    GoogleTagManagerHelper.disparaEventoGtm({
      event: EventsGTM.EFETUADO_LOGIN
    })
  }

  public static disparaEventoGtmDeFallback = (nomeFallback: string): void => {
    GoogleTagManagerHelper.disparaEventoGtm<Model.EventoGtmDeFallback>({
      event: EventsGTM.VISUALIZACAO_FALLBACK,
      action: nomeFallback
    })
  }

  public static disparaEventosFiltroListagem(filtro: EventoFiltroListagem) {
    const eventos: Array<FilterEvent & Record<string, unknown>> = []

    if (filtro.porUnidade) eventos.push(filtro.porUnidade)
    if (filtro.porDataPreferencia) eventos.push(filtro.porDataPreferencia)
    if (filtro.porGenero) eventos.push(filtro.porGenero)
    if (filtro.porDiaPreferencia) eventos.push(filtro.porDiaPreferencia)
    if (filtro.porTurno) eventos.push(filtro.porTurno)
    if (filtro.porDataSelecionada) eventos.push(filtro.porDataSelecionada)

    eventos.forEach(event => {
      this.disparaEventoGtm(event)
    })
  }

  public static disparaEventoExibirAlertaAgendamento(
    event: EventSchedulingAlert
  ) {
    this.disparaEventoGtm(event)
  }

  public static disparaEventoAlterarGeolocalizacao(ativa: boolean) {
    GoogleTagManagerHelper.disparaEventoGtm({
      event: EventsGTM.GEOLOCALIZACAO,
      action: ativa ? 'activated' : 'inactivated'
    })
  }
}

export default GoogleTagManagerHelper
