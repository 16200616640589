import { settings } from './settings'

const { APP_NAME, APP_VERSION, APP_CHANNEL } = settings

const headersEndpointsAgendamento = {
  'x-origin-application': APP_NAME,
  'x-origin-channel': APP_CHANNEL,
  'x-origin-version': APP_VERSION,
  client_id: settings.CLIENT_ID
}

export default headersEndpointsAgendamento
