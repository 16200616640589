import { Icon } from '@rededor/crdsl'
import StepIcon from 'components/StepBar/StepIcons/index'
import ProgressIcon from 'components/StepBar/progressIcons/index'
import TextBase from 'components/TextBase'
import { Consulta } from 'models/Consulta'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { NavigateOptions, useNavigate } from 'react-router-dom'
import { Color } from 'shared/Color'
import { store } from '../../../store'

import { atualizaEspecialidade } from 'store/especialidade/actions'
import { changeMultiStepBarStatus } from 'store/stepbar/actions'
import {
  ChangeStatusStepBar,
  ChangeStatusStepBarMulti,
  StepBarAction,
  StepBarActionUpdateStatus,
  StepName,
  StepStatus
} from 'store/stepbar/types'
import styled from 'styled-components'
import { history } from '../../browserhistory'
import {
  acessoLogadoEstaAtivo,
  etapaDeEscolhaDeUsuarioFoiConcluida
} from '../acessoLogadoHelper'
import MixpanelHelper from '../mixpanelHelper'
import { obtemPrimeiraPaginaDaAplicacao } from '../navegacaoHelper'
import { constroeTooltip } from '../tooltipHelper'
import { MemoryHistory } from 'history'
import MixPanelEventos from 'shared/consts/mixpanel/eventos'
import { settings } from 'shared/consts/settings'
import { AppSteps } from 'shared/Types/AppSteps'
import {
  isLoading,
  limparValidacaoElegibilidade,
  possuiHorario
} from '../../../store/consulta/actions'

const browserHistory = history as MemoryHistory

export interface ChangeMultipleStepProps {
  name: StepName
  status: StepStatus
  preventShow?: boolean
}

export const obtemSteps = (): StepBarAction[] => {
  return store
    .getState()
    .stepbar.stepbar.stepConfig.sort((a, b) => (a.id > b.id ? 1 : 0))
}

export const isSameStatusStep = (
  statusProximoStep: StepStatus | undefined,
  statusStepAtual: StepStatus | undefined
): boolean => {
  return statusProximoStep === statusStepAtual
}

export const isSameIdStep = (
  idProximoStep: number,
  idStepAtual?: number | undefined
): boolean => {
  return idProximoStep === idStepAtual
}

export const closeToolTipArea = (event: any, proximoStep: StepBarAction) => {
  const step = obtemSteps().find(step => step.name === proximoStep.name)

  const { id } = event.target

  if (id === 'fecharToolTip' || id === 'overLayToolTip') {
    changeStatusStep(step?.name as StepName, 'finished')

    return false
  }

  if (isSameStatusStep(step?.status as StepStatus, 'actual')) {
    return false
  }

  return true
}

export const stepBarOnMouseEnter = (
  ProximoStep: StepBarAction,
  stepActual?: StepBarAction
): boolean => {
  const preenchido = stepFoiPreenchido(ProximoStep?.name)
  const { id, status } = ProximoStep
  const idStepAtual = stepActual?.id

  if (!preenchido) {
    return false
  }

  return (
    (ProximoStep.hasToolTip && !isSameIdStep(id, idStepAtual)) ||
    isSameStatusStep(status as StepStatus, 'warning')
  )
}

export const navegaStepEspecilidade = () => {
  const stepEspecialidade = obtemSteps().find(
    step => step.name === 'especialidade-medico'
  ) as StepBarAction

  navegaParaOStep(stepEspecialidade)

  changeStatusStep('especialidade-medico', 'actual')
  changeStatusStep('paciente', 'finished')
}

export const navegaStepToolTip = (option: TooltipOption | undefined) => {
  if (option?.action) {
    const { dispatch } = store
    option.action()

    const nomeStepSelecionado = option?.etapa as StepName
    const nomeStepAtual = obtemSteps().find(step => step?.status === 'actual')
      ?.name as StepName

    if (nomeStepAtual !== nomeStepSelecionado) {
      if (!stepFoiPreenchido('especialidade-medico')) {
        dispatch(atualizaEspecialidade())
      }

      dispatch(limparValidacaoElegibilidade())
      changeStatusStep(nomeStepSelecionado, 'actual')
      changeStatusStep(nomeStepAtual, 'finished')
    }

    if (isMobile) {
      const EVENTOS: Record<string, string> = {
        'especialidade-medico': MixPanelEventos.EDITAR_ESPECIALIDADE,
        local: MixPanelEventos.EDITAR_LOCAL,
        paciente: MixPanelEventos.EDITAR_PACIENTE,
        pagamento: MixPanelEventos.EDITAR_PAGAMENTO
      }
      const eventName = EVENTOS[nomeStepSelecionado]
      MixpanelHelper.sendEventGeneric(eventName)
    }
  }
}

export const obtemStepsVisiveis = (
  steps?: StepBarAction[]
): StepBarAction[] => {
  return (steps || obtemSteps()).filter(step => !step.preventShow)
}

export const obtemNomeDaPaginaAtual = (): string => {
  const [nomeDaPaginaAtual] = history.location.pathname.split('/').reverse()

  return nomeDaPaginaAtual || ''
}

export const obtemStepAtual = (): StepBarAction | undefined => {
  const paginaAtual = obtemNomeDaPaginaAtual()

  return obtemStepsVisiveis().find(step => step.name === paginaAtual)
}
export interface TooltipOption {
  name: string
  url?: string
  etapa?: StepName | string
  action?: () => void
  widthSmall?: string
  hideSmall?: boolean
  hideLarge?: boolean
}

export interface StepbarTooltip {
  title: JSX.Element
  tooltipOptions: TooltipOption[]
}

const StyledTooltipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`

const StyledTooltipButton = styled.button`
  background-color: Transparent;
  color: #004186;
  font-weight: bold;
  padding: 0;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
`

const StyleNameToolTipButton = styled.span`
  line-height: 1;
`

export interface ConfigInterface {
  id: string
  status: StepStatus
  iconName: string
  stepbarTarget: boolean
  tooltipStepbar: StepbarTooltip
  preventShow: boolean
}
function tooltipBuilder(tooltip?: StepbarTooltip) {
  return (
    ((tooltip?.tooltipOptions && tooltip.tooltipOptions.length > 0) ||
      undefined) && (
      <StyledTooltipContainer>
        {tooltip?.title}
        {tooltip?.tooltipOptions?.map(
          option =>
            option && (
              <>
                {!option?.url && (
                  <TextBase
                    hideSmall={option?.hideSmall || false}
                    hideLarge={option?.hideLarge || false}
                    width={100}
                    widthSmall={option?.widthSmall ? option.widthSmall : '50%'}
                    margin="5px 0 0px 0"
                    bold
                    fontSize={12}
                    color="#4F4F4F"
                    flex
                    flexVerticalAlign={isMobile ? 'center' : 'flex-start'}
                  >
                    {option.name}
                    <Icon name="check" size="xxs" color="#4F4F4F" />
                  </TextBase>
                )}
                {option?.url && (
                  <TextBase
                    hideSmall={option?.hideSmall || false}
                    hideLarge={option?.hideLarge || false}
                    width={100}
                    widthSmall={option?.widthSmall ? option.widthSmall : 'auto'}
                    margin="5px 0 0px 0"
                    bold
                    colorHover="#004186"
                    fontSize={12}
                    flex
                    flexVerticalAlign={isMobile ? 'center' : 'flex-start'}
                  >
                    <StyledTooltipButton
                      type="button"
                      onClick={() => {
                        navegaStepToolTip(option)
                      }}
                    >
                      <StyleNameToolTipButton>
                        {option.name}
                      </StyleNameToolTipButton>

                      <Icon name="setaDireita" size="xxs" color="#004186" />
                    </StyledTooltipButton>
                  </TextBase>
                )}
              </>
            )
        )}
      </StyledTooltipContainer>
    )
  )
}

export function setStepBarSelected(
  name: string,
  iconName: string,
  stepbarTarget?: boolean,
  tooltip?: StepbarTooltip,
  preventShow?: boolean
) {
  const updateStep: StepBarActionUpdateStatus = {
    iconStats: <ProgressIcon type="selected" />,
    hasToolTip: tooltipBuilder(tooltip),
    textColor: '#004186',
    preventClick: false,
    preventShow,
    status: 'selected'
  }

  const updateStepMobile: StepBarActionUpdateStatus = {
    iconMobile: (
      <ProgressIcon
        iconName={iconName}
        color="#004186"
        borderColor="#004186"
        size="md"
      />
    )
  }

  const updateStebBarNode: ChangeStatusStepBar = {
    name,
    stepbarTarget,
    stepSelected: { ...updateStep, ...updateStepMobile }
  }

  return updateStebBarNode
}

export function setStepBarFinished(
  name: string,
  iconName: string,
  stepbarTarget?: boolean,
  tooltip?: StepbarTooltip,
  preventShow?: boolean
) {
  const updateStep: StepBarActionUpdateStatus = {
    icon: <StepIcon name={iconName} color="#004186" />,
    iconStats: <ProgressIcon type="finalised" />,
    hasToolTip: tooltipBuilder(tooltip),
    textColor: '#004186',
    preventClick: false,
    preventShow,
    status: 'finished'
  }

  const updateStepMobile: StepBarActionUpdateStatus = {
    iconMobile: <ProgressIcon iconName={iconName} color="#004186" size="md" />
  }

  const updateStebBarNode: ChangeStatusStepBar = {
    name,
    stepbarTarget,
    stepSelected: { ...updateStep, ...updateStepMobile }
  }

  return updateStebBarNode
}

export function setStepBarWarned(
  name: string,
  iconName: string,
  stepbarTarget?: boolean,
  tooltip?: StepbarTooltip,
  preventShow?: boolean
) {
  const updateStep: StepBarActionUpdateStatus = {
    icon: <StepIcon name={iconName} color="#DF8D2C" />,
    iconStats: <ProgressIcon type="warning" />,
    hasToolTip: tooltipBuilder(tooltip),
    status: 'warning',
    textColor: '#DF8D2C',
    preventShow
  }

  const updateStepMobile: StepBarActionUpdateStatus = {
    iconMobile: <ProgressIcon type="warning" size="xs" />
  }

  const updateStebBarNode: ChangeStatusStepBar = {
    name,
    stepbarTarget,
    stepSelected: { ...updateStep, ...updateStepMobile }
  }

  return updateStebBarNode
}

export function setStepBarActual(
  name: string,
  iconName?: string,
  stepbarTarget?: boolean,
  preventShow?: boolean
) {
  const updateStep: StepBarActionUpdateStatus = {
    iconStats: <ProgressIcon type="actual" />,
    textColor: Color.Azul_Escuro,
    status: 'actual',
    preventClick: false,
    preventShow
  }

  if (iconName) {
    Object.assign(updateStep, {
      icon: <StepIcon name={iconName} color={Color.Azul_Escuro} />
    })

    const updateStepMobile: StepBarActionUpdateStatus = {
      iconMobile: (
        <ProgressIcon
          type="actualMobile"
          color={Color.Azul_Escuro}
          iconName={iconName}
          size="md"
        />
      )
    }

    Object.assign(updateStep, updateStepMobile)
  }

  const updateStebBarNode: ChangeStatusStepBar = {
    name,
    stepbarTarget,
    stepSelected: updateStep
  }

  return updateStebBarNode
}

export function setStepBarInitial(
  name: string,
  iconName?: string,
  stepbarTarget?: boolean,
  preventShow?: boolean
) {
  const updateStep: StepBarActionUpdateStatus = {
    iconStats: <ProgressIcon type="initial" />,
    textColor: '#BDBDBD',
    preventClick: false,
    hasToolTip: undefined,
    status: undefined,
    preventShow
  }

  if (iconName) {
    Object.assign(updateStep, {
      icon: <StepIcon name={iconName} color="#BDBDBD" />
    })

    const updateStepMobile: StepBarActionUpdateStatus = {
      iconMobile: <Icon name={iconName} color="#BDBDBD" />
    }

    Object.assign(updateStep, updateStepMobile)
  }

  const updateStebBarNode: ChangeStatusStepBar = {
    name,
    stepbarTarget,
    stepSelected: updateStep
  }

  return updateStebBarNode
}

export const setMultiStepBar = (config: ConfigInterface[]) => {
  const { dispatch } = store
  const finalConfig: ChangeStatusStepBar[] = []

  config.forEach(step => {
    if (isSameStatusStep(step.status, 'finished')) {
      finalConfig.push(
        setStepBarFinished(
          step.id,
          step.iconName,
          step.stepbarTarget,
          step.tooltipStepbar,
          step.preventShow
        )
      )
    }
    if (isSameStatusStep(step.status, 'warning')) {
      finalConfig.push(
        setStepBarWarned(
          step.id,
          step.iconName,
          step.stepbarTarget,
          step.tooltipStepbar,
          step.preventShow
        )
      )
    }
    if (isSameStatusStep(step.status, 'initial')) {
      finalConfig.push(
        setStepBarInitial(
          step.id,
          step.iconName,
          step.stepbarTarget,
          step.preventShow
        )
      )
    }
    if (!step.status || isSameStatusStep(step.status, 'actual')) {
      finalConfig.push(
        setStepBarActual(
          step.id,
          step.iconName,
          step.stepbarTarget,
          step.preventShow
        )
      )
    }
    if (isSameStatusStep(step.status, 'selected')) {
      finalConfig.push(
        setStepBarSelected(
          step.id,
          step.iconName,
          step.stepbarTarget,
          step.tooltipStepbar,
          step.preventShow
        )
      )
    }
  })

  const updateStebBarFinal: ChangeStatusStepBarMulti = {
    config: [...finalConfig]
  }

  dispatch(changeMultiStepBarStatus(updateStebBarFinal))
}

export const changeStatusStep = (
  nomeDoStep: StepName,
  novoStatus: StepStatus,
  esconder = false
): void => {
  const step = obtemSteps().find(step => step.name === nomeDoStep)

  if (step) {
    const stepAlterado: ConfigInterface = {
      id: step.name,
      status: novoStatus,
      iconName: step.iconName,
      stepbarTarget: novoStatus === 'actual',
      tooltipStepbar: constroeTooltip(step.name),
      preventShow: esconder
    }

    setMultiStepBar([stepAlterado])
  }
}
export const changeMultipleStep = (steps: ChangeMultipleStepProps[]) => {
  if (!steps) return

  steps.forEach(step => {
    changeStatusStep(step.name, step.status, step.preventShow)
  })
}

export const stepFoiPreenchido = (nomeDoStep: StepName): boolean => {
  const { consulta } = store.getState()
  const { local, unidade, especialidade, paciente, pagamento } = consulta

  const isStepPatientFilled = Boolean(
    paciente?.dataNascimento && paciente.genero
  )

  const criteriosDePreenchimentoDosSteps = {
    'especialidade-medico': typeof especialidade !== 'undefined',
    local: typeof local !== 'undefined',
    paciente: isStepPatientFilled,
    pagamento: typeof pagamento !== 'undefined',
    'agendar-consulta':
      typeof especialidade !== 'undefined' &&
      typeof local !== 'undefined' &&
      typeof unidade !== 'undefined' &&
      typeof paciente !== 'undefined' &&
      typeof pagamento !== 'undefined'
  }

  return criteriosDePreenchimentoDosSteps[nomeDoStep]
}

const stepPagamentoNaoPodeEstaComoAtual = (nomeDoStep: any) => {
  const state = store.getState()
  const stepLocalEstaEscondido = state.stepbar.stepbar.stepConfig[1].preventShow
  const stepAtualEStepPagamento = nomeDoStep === 'pagamento'

  if (stepAtualEStepPagamento && !stepLocalEstaEscondido) {
    const setLocalFoiPreenchido = stepFoiPreenchido('local')

    if (!setLocalFoiPreenchido) {
      return true
    }
  }

  return false
}

const obtemNovoStatusDoStep = (
  nomeDoStep: StepName,
  stepInvalido: StepName | undefined,
  todosOsSteps: StepBarAction[]
): StepStatus => {
  if (
    nomeDoStep === 'agendar-consulta' &&
    todosOsSteps.some(
      step =>
        (isSameStatusStep(step.status, 'actual') ||
          isSameStatusStep(step.status, 'warning')) &&
        step.name !== 'agendar-consulta'
    )
  ) {
    return 'initial'
  }

  const invalido = nomeDoStep === stepInvalido
  if (invalido) {
    return 'warning'
  }

  const preenchido = stepFoiPreenchido(nomeDoStep)
  const indexDoStep = todosOsSteps.findIndex(step => step.name === nomeDoStep)
  const stepAnterior =
    indexDoStep === 0 ? undefined : todosOsSteps[indexDoStep - 1]

  if (preenchido) {
    if (
      nomeDoStep === 'agendar-consulta' &&
      stepAnterior &&
      isSameStatusStep(stepAnterior.status, 'finished')
    ) {
      return 'actual'
    }

    return 'finished'
  }

  if (!stepAnterior) {
    return 'actual'
  }

  if (stepAnterior.preventShow) {
    const [primeiroStepAnteriorVisivel] = todosOsSteps
      .slice(0, indexDoStep)
      .reverse()
      .filter(step => !step.preventShow)

    if (
      primeiroStepAnteriorVisivel &&
      isSameStatusStep(primeiroStepAnteriorVisivel.status, 'finished')
    ) {
      return 'actual'
    }
  }

  if (isSameStatusStep(stepAnterior.status, 'finished')) {
    return stepPagamentoNaoPodeEstaComoAtual(nomeDoStep) ? 'initial' : 'actual'
  }

  return 'initial'
}

export const stepDeveSerEscondido = (stepName: StepName): boolean => {
  const { consulta } = store.getState()
  const { escolhaPorMedico } = consulta

  if (stepName === 'local') {
    return escolhaPorMedico === true
  }

  return false
}

export const atualizaStatusDosSteps = (
  stepInvalido?: StepName
): StepName | undefined => {
  const steps = obtemSteps()

  steps.forEach(step => {
    const esconder = stepDeveSerEscondido(step.name)
    const status: StepStatus = esconder
      ? 'initial'
      : obtemNovoStatusDoStep(step.name, stepInvalido, obtemSteps())

    changeStatusStep(step.name, status, esconder)
  })

  if (stepInvalido) return undefined

  return obtemSteps().find(step => step.status === 'actual')?.name
}

export const desabilitaTodosOsSteps = (): void => {
  const state = store.getState()
  const steps = state.stepbar.stepbar.stepConfig.sort(
    (stepA, stepB) => stepA.id - stepB.id
  )

  steps.forEach(step => {
    const esconder = stepDeveSerEscondido(step.name)
    changeStatusStep(step.name, 'initial', esconder)
  })
}
const stepsEstaoInicializados = (steps?: StepBarAction[]): boolean => {
  return obtemStepsVisiveis(steps) && obtemStepsVisiveis(steps).length > 0
}

const algumStepJaFoiInicializadoENaoPossuiErros = (
  steps?: StepBarAction[]
): boolean => {
  return obtemStepsVisiveis(steps).some(
    step =>
      typeof step.status !== 'undefined' &&
      !['initial', 'warning'].includes(step.status)
  )
}

const obtemIndexDoStepAtual = (): number => {
  const stepAtual = obtemStepAtual()
  const nomesDosStepsAtuais: string[] = obtemStepsVisiveis().map(
    step => step.name
  )
  if (stepAtual) {
    return obtemStepsVisiveis().findIndex(step => step.name === stepAtual.name)
  }
  if (!nomesDosStepsAtuais.includes(obtemNomeDaPaginaAtual())) {
    return obtemStepsVisiveis().length
  }

  return 0
}

const obtemStepAnteriorNavegavel = (): StepBarAction | undefined => {
  const indexDoStepAtual = obtemIndexDoStepAtual()

  return obtemStepsVisiveis()
    .filter(
      (step, index) =>
        index < indexDoStepAtual &&
        !step.preventShow &&
        step.status &&
        ['actual', 'finished', 'selected'].includes(step.status)
    )
    .reverse()[0]
}

const obtemProximoStepNavegavel = (): StepBarAction | undefined => {
  const indexDoStepAtual = obtemIndexDoStepAtual()

  return obtemStepsVisiveis().find(
    (step, index) =>
      index > indexDoStepAtual &&
      !step.preventShow &&
      step.status &&
      ['actual', 'finished', 'selected'].includes(step.status)
  )
}

export const navegaParaOStep = (step: StepBarAction): void => {
  step.action()
  browserHistory.push(`/paciente/marcar-consulta/${step.name}`)
}

export function navegaEntreSteps(
  proximoStep: StepBarAction,
  stepAtual?: StepBarAction
): boolean {
  const { dispatch } = store
  dispatch(limparValidacaoElegibilidade())
  dispatch(possuiHorario(false))
  dispatch(isLoading(false))

  const stepsStatusSelected = obtemSteps()
    .filter(step => step.status === 'selected')
    .find(step => step.name !== proximoStep.name)

  const isStatusWarmning = obtemSteps().some(step => step.status === 'warning')

  if (!isSameIdStep(proximoStep.id, stepAtual?.id)) {
    const preenchido = stepFoiPreenchido(proximoStep.name)

    if (!stepFoiPreenchido('especialidade-medico')) {
      dispatch(atualizaEspecialidade())
    }

    if (!isMobile) {
      if (preenchido && isSameStatusStep(proximoStep.status, 'finished')) {
        changeStatusStep(proximoStep.name, 'actual')
        changeStatusStep(stepAtual?.name as StepName, 'finished')
        navegaParaOStep(proximoStep)

        return true
      }
    }

    if (!preenchido && isSameStatusStep(proximoStep.status, 'finished')) {
      changeStatusStep(proximoStep.name, 'actual')
      changeStatusStep(stepAtual?.name as StepName, 'finished')
      changeStatusStep(stepsStatusSelected?.name as StepName, 'finished')

      navegaParaOStep(proximoStep)

      return false
    }

    const podeNavegar =
      isSameIdStep(proximoStep.id, stepAtual?.id as number) &&
      isSameStatusStep(proximoStep?.status as StepStatus, 'actual')

    if (podeNavegar) {
      navegaParaOStep(proximoStep)

      return true
    }

    const verificaSteps = isSameIdStep(proximoStep.id, stepAtual?.id)

    if (proximoStep.hasToolTip && !verificaSteps) {
      changeStatusStep(proximoStep.name, 'selected')
      changeStatusStep(stepsStatusSelected?.name as StepName, 'finished')
      if (!isStatusWarmning) {
        changeStatusStep(stepAtual?.name as StepName, 'actual')
      }

      return true
    }
  }

  if (stepAtual?.status === 'warning') {
    changeStatusStep(stepsStatusSelected?.name as StepName, 'finished')

    return true
  }

  changeStatusStep(stepsStatusSelected?.name as StepName, 'finished')

  return false
}

export const podeVoltarNavegacao = (steps?: StepBarAction[]): boolean => {
  if (
    stepsEstaoInicializados(steps) &&
    algumStepJaFoiInicializadoENaoPossuiErros(steps)
  ) {
    const indexDoStepAtual = obtemIndexDoStepAtual()
    if (indexDoStepAtual > 0) {
      return true
    }
  }

  return false
}

export const voltaStep = (): void => {
  const stepAnterior = obtemStepAnteriorNavegavel()
  if (stepAnterior) {
    navegaParaOStep(stepAnterior)
  }
}

export const podeAvancarNavegacao = (steps?: StepBarAction[]): boolean => {
  if (
    stepsEstaoInicializados(steps) &&
    algumStepJaFoiInicializadoENaoPossuiErros(steps)
  ) {
    const proximoStep = obtemProximoStepNavegavel()
    if (proximoStep) {
      return true
    }
  }

  return false
}

export const avancaStep = (): void => {
  const proximoStep = obtemProximoStepNavegavel()
  if (proximoStep) {
    navegaParaOStep(proximoStep)
  }
}
export const quebraLinhaStepBar = (text: string) => {
  const mensagem = text.split('\n')

  return (
    <>
      <p style={{ margin: '0', fontWeight: 'bold' }}>
        {mensagem[0]}
        <br />
        {mensagem[1]}
      </p>
    </>
  )
}

export const segueParaProximoStepDoFluxo = (): void => {
  const proximoStep = atualizaStatusDosSteps()

  browserHistory.push(`${process.env.REACT_APP_PUBLIC_URL}/${proximoStep}`)
}

export type DadosExigidos =
  | 'especialidade'
  | 'paciente'
  | 'medico'
  | 'pagamento'
  | 'unidade'
  | 'horario'
  | 'sala'

const dadosExistemNoStore = (
  listaDeDadosExigidos: DadosExigidos[]
): boolean => {
  let dadosExistem = true
  const dadosNoStore = Object.keys(store.getState().consulta)

  listaDeDadosExigidos.forEach(dadoExigido => {
    if (!dadosNoStore.includes(dadoExigido)) {
      dadosExistem = false

      return false
    }
  })

  return dadosExistem
}

export const existemDadosValidosParaExibicaoDeStep = async (
  ...listaDeDadosExigidos: DadosExigidos[]
): Promise<boolean> => {
  return await new Promise(resolve => {
    if (
      (acessoLogadoEstaAtivo() && !etapaDeEscolhaDeUsuarioFoiConcluida()) ||
      !dadosExistemNoStore(listaDeDadosExigidos)
    ) {
      resolve(false)
      browserHistory.push(
        `${
          process.env.REACT_APP_PUBLIC_URL
        }/${obtemPrimeiraPaginaDaAplicacao()}`
      )
    } else {
      resolve(true)
    }
  })
}

export const dadosObrigatorios: Record<string, DadosExigidos[]> = {
  sucesso: [
    'especialidade',
    'paciente',
    'pagamento',
    'medico',
    'unidade',
    'horario'
  ]
}

export const useStepBar = () => {
  const navigate = useNavigate()

  const navigateTo = (
    step: AppSteps,
    state?: Pick<NavigateOptions, 'state'>
  ) => {
    navigate(`${settings.PUBLIC_URL}/${step}`, state)
  }

  return { navigateTo }
}

export type NomeSteps =
  | 'especialidade-medico'
  | 'local'
  | 'paciente'
  | 'pagamento'
  | 'agendar-consulta'

export const gerarNomeStepsModificados = (stepName: NomeSteps) =>
  ({
    'especialidade-medico': 'Especialidade',
    local: 'Local',
    paciente: 'Paciente',
    pagamento: 'Pagamento',
    'agendar-consulta': 'Agenda'
  })[stepName]

export const manipulaDadosPreenchidosEspecialidadeMedico = (
  dadosConsulta: Consulta
) => {
  const teleconsultaOuPresencial = dadosConsulta.agendamentoTeleconsulta
    ? 'Teleconsulta'
    : 'Presencial'

  if (!dadosConsulta.especialidade?.descricao) return ''

  if (dadosConsulta.escolhaPorMedico) {
    return `${dadosConsulta.medico?.nome} - ${teleconsultaOuPresencial}`
  }

  return `${dadosConsulta.especialidade?.descricao} - ${teleconsultaOuPresencial}`
}

export const manipulaDadosPreenchidosLocal = (dadosConsulta: Consulta) => {
  if (!dadosConsulta.local?.nome) return ''

  return `${dadosConsulta.local.nome} - ${dadosConsulta.local.uf}`
}

export const manipulaDadosPreenchidosPaciente = (dadosConsulta: Consulta) => {
  const { paciente } = dadosConsulta

  if (!paciente?.dataNascimento) return ''

  const genero =
    paciente.genero === 'M'
      ? 'Masculino'
      : paciente.genero === 'F'
      ? 'Feminino'
      : ''

  const generoFormatado = genero ? ` - ${genero}` : ''

  return `${paciente.dataNascimento
    .split('-')
    .reverse()
    .join('/')} ${generoFormatado}`
}

export const manipulaDadosPreenchidosPagamento = (dadosConsulta: Consulta) => {
  if (
    !dadosConsulta.pagamento?.convenio?.nome &&
    !dadosConsulta.pagamento?.plano?.nome
  ) {
    return ''
  }

  if (dadosConsulta.pagamento?.convenio?.nome) {
    return `${dadosConsulta.pagamento?.convenio?.nome
      .split(' ')
      .map(texto => `${texto[0]}${texto.toLocaleLowerCase().slice(1)}`)
      .join(' ')}`
  }

  if (!dadosConsulta.pagamento?.convenio?.nome) {
    return dadosConsulta.pagamento?.plano?.nome
  }
}

export const stepsOrdenadosComDadosPreenchidos = (
  stepsVisiveisEOrdenados: StepBarAction[],
  dadosConsulta: Consulta
) => {
  return stepsVisiveisEOrdenados.map(
    step =>
      ({
        'especialidade-medico': {
          ...step,
          dadosPreenchidosStep:
            manipulaDadosPreenchidosEspecialidadeMedico(dadosConsulta)
        },
        local: {
          ...step,
          dadosPreenchidosStep: manipulaDadosPreenchidosLocal(dadosConsulta)
        },
        paciente: {
          ...step,
          dadosPreenchidosStep: manipulaDadosPreenchidosPaciente(dadosConsulta)
        },
        pagamento: {
          ...step,
          dadosPreenchidosStep: manipulaDadosPreenchidosPagamento(dadosConsulta)
        },
        'agendar-consulta': step
      })[step.name]
  )
}
