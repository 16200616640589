import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { env } from 'shared/consts/env'

const sentryInitOptions: Sentry.BrowserOptions = {
  dsn: env.sentry.dsn,
  enabled: env.sentry.enabled,
  environment: env.sentry.environment,
  release: env.sentry.release,
  sampleRate: 0.3, // errors monitoring
  tracesSampleRate: 0, // performance monitoring
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.1,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false
    })
  ]
}

export const settings = {
  sentryInitOptions
}
