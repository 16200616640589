import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { StepName } from 'store/stepbar/types'

export const useLocationStep = () => {
  const location = useLocation()
  const locationRef = useRef('')

  const getCurrentLocationStep = () => {
    const pathSegments = locationRef.current.split('/')
    return pathSegments[pathSegments.length - 1] as StepName
  }

  useEffect(() => {
    locationRef.current = location.pathname
  }, [location])

  return {
    getCurrentLocationStep
  }
}
