import { useCallback, useEffect, useRef, useState } from 'react'

export default (mediaQueryString: string) => {
  const [match, setMatch] = useState(() => {
    const { matches } = matchMedia(mediaQueryString)

    return matches
  })

  const queryList = useRef<MediaQueryList>(matchMedia(mediaQueryString))

  const onChangeMatch = useCallback(({ matches }: any) => {
    setMatch(matches)
  }, [])

  const startMatchListener = useCallback(() => {
    if (!('addEventListener' in queryList.current) || !queryList.current) {
      return
    }

    queryList?.current?.addEventListener('change', onChangeMatch)
  }, [onChangeMatch])

  useEffect(() => {
    startMatchListener()
    return () => {
      if (queryList.current) {
        queryList?.current?.removeEventListener('change', onChangeMatch)
      }
    }
  }, [startMatchListener, onChangeMatch])

  return { match }
}
