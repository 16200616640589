import styled, { css } from 'styled-components'

import { LoadingIconSizeTypes, LoadingPropTypes } from './types'

const sizes = {
  xxs: '16px',
  xs: '24px',
  md: '32px',
  lg: '48px',
  xl: '64px',
  xxl: '128px'
}

const svgSizeModifier = (size: LoadingIconSizeTypes) => css`
  width: ${`${sizes[size]}`};
  height: ${`${sizes[size]}`};
`

const customSizeModifier = (pixels: string) => css`
  width: ${`${pixels}px`};
  height: ${`${pixels}px`};
`

export const WrapperLoading = styled.div<LoadingPropTypes>`
  ${({ size, customSizeInPx }) => css`
    & svg {
      ${customSizeInPx
        ? customSizeModifier(customSizeInPx)
        : svgSizeModifier(size || 'md')}
    }
  `}
`
