import React from 'react'
import { ApplicationLoaderPropTypes } from './types'

import * as S from './styles'

const ApplicationLoader = ({ children }: ApplicationLoaderPropTypes) => (
  <S.WrapperLoaderList>{children}</S.WrapperLoaderList>
)

export default ApplicationLoader
