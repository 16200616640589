export const EventsGTM = Object.freeze({
  VISUALIZACAO_PAGINA: 'virtualPageView',
  VISUALIZACAO_FALLBACK: 'showFallback',
  MODALIDADE_CONSULTA: 'selectExpertise',
  SELECAO_LOCAL: 'selectLocal',
  SELECAO_PAGAMENTO: 'payment',
  SELECAO_CONVENIO: 'selectMedicalInsurance',
  DADOS_PARCIAIS_PACIENTE: 'insertBirthday',
  CARREGANDO_HORARIOS: 'startLoading',
  CARREGADO_HORARIOS: 'finishLoading',
  SELECAO_HORARIO: 'scheduling',
  CONFIRMA_REVISAO: 'confirmScheduling',
  CONFIRMA_AGENDAMENTO: 'confirmPatient',
  EFETUADO_LOGIN: 'login',
  GEOLOCALIZACAO: 'geoLocation',
  FILTRO_UNIDADE: 'filterUnit',
  FILTRO_DATA: 'filterDate',
  FILTRO_GENERO: 'filterGender',
  FILTRO_DIA: 'filterDays',
  FILTRO_TURNO: 'filterShift',
  FILTRO_DATA_SELECIONADA: 'filterScheduleOption',
  ALERTA_AGENDAMENTO: 'schedulingAlert'
})
