import { add, isAfter, parseISO } from 'date-fns'

import { buildLocalParam } from 'utils/helpers/endpointParamHelper'
import { Horarios } from '../../../models/Horario'
import { CarouselDateOption } from '../../../components/CalendarFilters/interfaces'
import { UnidadeAlgolia } from '../../../models/Unidade'
import quantidadeDeDiasPorBuscaDeOferta from '../../../shared/consts/quantidadeDeDiasPorBuscaDeOferta'
import { DiaOfertaBackend } from '../../../models/HorarioOferta'
import { OfertaResumo } from './interfaces'
import {
  ParamsListagemHorarios,
  ParamsListagemHorariosPorEspecialidade,
  ParamsListagemHorariosPorMedico,
  PeriodoOfertas
} from '../../../utils/helpers/horariosHelper/interfaces'
import {
  converteHorariosEmDiaOferta,
  converteDiaOfertaEmHorarios
} from '../../../utils/helpers/horariosHelper'
import { obtemUnidadesValidacao } from '../../../utils/helpers/unidadeHelper'
import { store } from '../../../store'

const constroeParametrosDoEndpoint = (
  inicioPeriodo: string,
  fimPeriodo: string
): ParamsListagemHorarios => {
  const { especialidade, paciente, pagamento, codigoRequisicao } =
    store.getState().consulta

  return {
    plano: String(pagamento?.plano?.idRdsl),
    fimPeriodo,
    inicioPeriodo,
    especialidade: String(especialidade?.id),
    dataNascimento: String(paciente?.dataNascimento),
    codigoRequisicao: String(codigoRequisicao)
  }
}

const constroeParametrosDoEndpointPorMedico = (
  inicioPeriodo: string,
  fimPeriodo: string
): ParamsListagemHorariosPorMedico => {
  const { medico, paciente } = store.getState().consulta

  return {
    ...constroeParametrosDoEndpoint(inicioPeriodo, fimPeriodo),
    genero: String(paciente?.genero),
    medico: String(medico?.id)
  }
}

const constroeParametrosDoEndpointPorEspecialidade = (
  inicioPeriodo: string,
  fimPeriodo: string
): ParamsListagemHorariosPorEspecialidade => {
  const { local, localSugestoes, expansionSuggestion } =
    store.getState().consulta
  const { id: localId, idUnidadePrincipalRdsl } = local || {}

  const localParam = buildLocalParam(local, localSugestoes, expansionSuggestion)

  return {
    ...constroeParametrosDoEndpoint(inicioPeriodo, fimPeriodo),
    local: localParam,
    localPaciente: String(idUnidadePrincipalRdsl || localId)
  }
}

const removeDiasDuplicados = (
  horariosJaCarregados: Horarios,
  horariosNovos: Horarios
): Horarios => {
  const listaFinal = converteHorariosEmDiaOferta({
    ...horariosJaCarregados,
    ...horariosNovos
  })

  return converteDiaOfertaEmHorarios(
    listaFinal.sort((diaA, diaB) => diaA.data.localeCompare(diaB.data))
  )
}

const obtemDatasDisponiveis = (dias: Horarios): CarouselDateOption[] => {
  return Object.values(dias).map((dia, index) => ({
    date: parseISO(dia.data),
    id: index
  }))
}

const obtemUnidadesDasOfertas = (ofertas: Horarios): UnidadeAlgolia[] => {
  const unidades: UnidadeAlgolia[] = []

  converteHorariosEmDiaOferta(ofertas).forEach(oferta => {
    oferta.medicos.forEach(medicoOferta => {
      medicoOferta.unidades.forEach(unidadeOferta => {
        if (
          !unidades.some(unidade => unidade.id === unidadeOferta.unidade.id)
        ) {
          unidades.push(unidadeOferta.unidade)
        }
      })
    })
  })

  return unidades
}

const obtemQuantidadeDeDiasPorChamada = (): number => {
  const { escolhaPorEspecialidade, local } = store.getState().consulta
  if (escolhaPorEspecialidade && local) {
    if (local.tipo !== 'Hospital') {
      return quantidadeDeDiasPorBuscaDeOferta.ofertaPorEspecialidade
        .emLocaisMaisAbrangentes
    }

    return quantidadeDeDiasPorBuscaDeOferta.ofertaPorEspecialidade
      .emHospitalEspecifico
  }

  return quantidadeDeDiasPorBuscaDeOferta.ofertasPorMedico
}
const obtemTituloDoLoader = (): string => {
  const consultaState = store.getState()?.consulta

  if (!consultaState) return ''
  const { escolhaPorEspecialidade, local } = consultaState

  let tituloDoLoader = 'Buscando'

  if (escolhaPorEspecialidade && local) {
    const { tipo, nome } = local

    const tipoMapeamento: Record<string, string> = {
      Bairro: `no bairro ${nome}`,
      Hospital: `na unidade ${nome}`
    }

    const tipoTexto = tipoMapeamento[tipo] ?? `na região ${nome}`
    tituloDoLoader += ` ${tipoTexto}`
  }

  return tituloDoLoader
}

const obtemTextoDoLoader = (): string => {
  const { escolhaPorEspecialidade, local } = store.getState()?.consulta

  if (!escolhaPorEspecialidade || !local) {
    return 'nas unidades de atendimento'
  }

  let localidadeDaBuscaNoTextoDoLoader = local.nome
  if (local.tipo === 'Estado') {
    localidadeDaBuscaNoTextoDoLoader += ' (Estado)'
  } else if (local.tipo === 'Cidade') {
    localidadeDaBuscaNoTextoDoLoader += ` - ${local.uf}`
  }

  return localidadeDaBuscaNoTextoDoLoader
}
const obtemResumoDeOfertasDoDia = (
  ofertasDoDia: DiaOfertaBackend
): OfertaResumo[] => {
  return ofertasDoDia.medicos.map(medicoOferta => ({
    id: medicoOferta.medico.id,
    unidades: medicoOferta.unidades.map(unidadeOferta => ({
      id: Number(unidadeOferta.unidade.id),
      horarios: unidadeOferta.horarios.map(horario => horario.periodo)
    }))
  }))
}

const constroePeriodoParaBusca = (
  ultimaDataBuscada?: Date
): PeriodoOfertas | undefined => {
  const hoje = new Date()
  const dataLimiteParaBusca = add(hoje, { days: 60 })
  const quantidadeDeDiasPorChamada = obtemQuantidadeDeDiasPorChamada()

  const inicio = !ultimaDataBuscada
    ? hoje
    : add(ultimaDataBuscada, { days: quantidadeDeDiasPorChamada })

  if (isAfter(inicio, dataLimiteParaBusca)) {
    return undefined
  }

  let fim = add(inicio, { days: quantidadeDeDiasPorChamada })
  if (isAfter(fim, dataLimiteParaBusca)) {
    fim = add(dataLimiteParaBusca, { days: 1 })
  }

  return { inicio, fim }
}

const ofertasSaoDivergentes = (
  listaA: DiaOfertaBackend,
  listaB: DiaOfertaBackend
): boolean => {
  const resumoListaA = obtemResumoDeOfertasDoDia(listaA)
  const resumoListaB = obtemResumoDeOfertasDoDia(listaB)

  return JSON.stringify(resumoListaA) !== JSON.stringify(resumoListaB)
}

const hasCoveragePlanWithLocal = (validation: any) => {
  const { local, escolhaPorEspecialidade } = store.getState().consulta

  if (local?.tipo === 'Hospital' && escolhaPorEspecialidade) {
    const unitsValidation = obtemUnidadesValidacao(validation)
    const localIdRdsl = String(local?.idRdsl)

    return unitsValidation?.some(unit => String(unit.idRdsl) === localIdRdsl)
  }

  return true
}

const hasAssistanceFailure = (validations: any) => {
  return validations?.some(
    (validation: { validacao: string; elegivel: any }) =>
      validation.validacao === 'EspecialidadeAtendidaEmLocal' &&
      !validation.elegivel
  )
}

export const AgendamentoHelper = {
  hasCoveragePlanWithLocal,
  constroeParametrosDoEndpoint,
  constroeParametrosDoEndpointPorMedico,
  constroeParametrosDoEndpointPorEspecialidade,
  removeDiasDuplicados,
  obtemDatasDisponiveis,
  obtemUnidadesDasOfertas,
  obtemQuantidadeDeDiasPorChamada,
  obtemTituloDoLoader,
  obtemTextoDoLoader,
  obtemResumoDeOfertasDoDia,
  constroePeriodoParaBusca,
  ofertasSaoDivergentes,
  hasAssistanceFailure
}
