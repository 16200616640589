import { ReactComponent as LoadingIcon } from 'assets/svgs/Loading.svg'
import React from 'react'

import { LoadingPropTypes } from './types'

import * as S from './styles'

const Loading = ({ size, customSizeInPx }: LoadingPropTypes) => (
  <S.WrapperLoading size={size} customSizeInPx={customSizeInPx}>
    <LoadingIcon />
  </S.WrapperLoading>
)

export default Loading
