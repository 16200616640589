export const settings = {
  COOKIE_CONSENT_TENANT_UUID: String(
    process.env.REACT_APP_COOKIE_CONSENT_TENANT_UUID
  ),
  COOKIE_CONSENT_DOMAIN_UUID: String(
    process.env.REACT_APP_COOKIE_CONSENT_DOMAIN_UUID
  ),
  PUBLIC_URL: String(process.env.REACT_APP_PUBLIC_URL),
  APP_VERSION: String(process.env.REACT_APP_VERSION),
  APP_NAME: String(process.env.REACT_APP_APPLICATION_NAME),
  APP_CHANNEL: String(process.env.REACT_APP_CHANNEL),
  URL_FOTO_MEDICO: String(process.env.REACT_APP_URL_FOTO_MEDICO),
  SUGGESTION_SCHEDULE_LIMIT: Number(
    process.env.REACT_APP_SUGGESTION_SCHEDULE_LIMIT
  ),
  MINIMUN_SCHEDULE_SLOT_BY_PERIOD: Number(
    process.env.REACT_APP_MINIMUN_SCHEDULE_SLOT_BY_PERIOD
  ),
  CLIENT_ID: String(process.env.REACT_APP_CLIENT_ID),
  MIXPANEL_DEVICE_ID_PROPERTY: '$device_id',
  MIXPANEL_DEVICE_ID_SESSION_KEY: 'mixpanel_device_id',
  SCHEDULE_LEAD_WEBHOOK: {
    ACTIVE: process.env.REACT_APP_SCHEDULE_LEAD_WEBHOOK_ACTIVE === 'true',
    UTM_SOURCE: 'tuasaude',
    TOKEN: String(process.env.REACT_APP_SCHEDULE_LEAD_WEBHOOK_TOKEN),
    GA_COOKIE_NAME: String(
      process.env.REACT_APP_SCHEDULE_LEAD_WEBHOOK_GA_COOKIE_NAME
    )
  }
}
