const MixPanelPropriedades = {
  APP_SESSION_ID: 'app_session_id',
  APLICACAO_NOME: 'Aplicacao',
  APLICACAO_VERSAO: 'App Version',

  MODALIDADE: 'Modalidade',
  FLUXO_DE_ATENDIMENTO: 'Fluxo de Atendimento',

  MEDICO_CODIGO: 'Codigo do Medico da Consulta',
  MEDICO_CODIGO_RDSL: 'Codigo RDSL do Medico',
  MEDICO_CRM: 'CRM do Medico',
  MEDICO_NOME: 'Nome do Medico',
  MEDICO_GENERO: 'Genero do Medico',

  ESPECIALIDADE_CODIGO: 'Codigo da Especialidade da Consulta',
  ESPECIALIDADE_DESCRICAO: 'Descricao da Especialidade',
  ESPECIALIDADE_POSSUI_CONSULTA_DIGITAL:
    'Especialidade possui Consulta Digital',
  ESPECIALIDADE_POSSUI_ATENDIMENTO_ONLINE:
    'Especialidade possui Atendimento Online',
  SUGESTAO: 'Sugestao',

  LOCAL_UF: 'UF',
  LOCAL_CODIGO: 'Codigo do Local',
  LOCAL_CODIGO_RDSL: 'Codigo RDSL do Local',
  LOCAL_DESCRICAO: 'Descricao do Local',
  LOCAL_TIPO: 'Tipo do Local',

  PACIENTE_DATA_NASCIMENTO: 'Data de Nascimento do Paciente',
  PACIENTE_GENERO: 'Genero do Paciente',

  UNIDADE_CODIGO: 'Codigo da Unidade da Consulta',
  UNIDADE_NOME: 'Nome da Unidade',
  UNIDADE_SETOR: 'Codigo do Setor da Unidade da Consulta',

  FORMA_DE_PAGAMENTO: 'Forma de Pagamento',
  CONVENIO_CODIGO: 'Codigo do Convenio',
  CONVENIO_NOME: 'Nome do Convenio',
  PLANO_CODIGO: 'Codigo do Plano',
  PLANO_NOME: 'Nome do Plano',
  FORMA_DE_SELECAO_COVENIO: 'Forma de selecao do convenio',
  FORMA_DE_SELECAO_PLANO: 'Forma de selecao do plano',

  CODIGO_REQUISICAO: 'Codigo Requisicao',

  // Etapa Local
  GEOLOCALIZACAO_ATIVADA: 'Geolocalizacao Ativada',
  FONTE_LISTA: 'Fonte da Lista',
  LISTA_EXIBIDA: 'Lista Exibida',

  // Exibir erro de elegibilidade
  FALLBACK_EXIBIDO: 'Fallback exibido',
  NOME_FALLBACK_ELEGIBILIDADE: 'Nome Fallback',
  MENSAGEM_FALLBACK_ELEGIBILIDADE: 'Mensagem Exibida',
  ULTIMO_STEP_ALTERADO: 'Ultimo step alterado',

  VALOR_CONSULTA: 'Valor da Consulta',
  FALLBACK_CONSULTA_PRECO: 'Fallback de Consulta de Preco',

  FALLBACK_FILTRO_SEM_RESULTADOS: 'Fallback de Filtro Sem Resultados',
  FILTRO_OFERTA_DATA_PREFERENCIA: 'Data De Preferencia',
  FILTRO_OFERTA_DIAS_SEMANA_PREFERENCIA: 'Dias da Semana Filtrados',
  FILTRO_OFERTA_CODIGOS_UNIDADES_PREFERENCIA: 'Codigos Unidades Filtradas',
  FILTRO_OFERTA_NOMES_UNIDADES_PREFERENCIA: 'Nomes Unidades Filtradas',
  FILTRO_OFERTA_TURNOS_PREFERENCIA: 'Turnos Filtrados',
  FILTRO_OFERTA_GENEROS_PREFERENCIA: 'Generos Filtrados',

  PAGINA_ORIGEM: 'Pagina de Origem',

  DATA_OFERTA_ESCOLHIDA: 'Datas da Oferta de Agenda Escolhida',
  HORARIO_CONSULTA: 'Horario da Consulta',
  FALLBACK_HORARIO_RESERVADO: 'Fallback de Horario Reservado',
  MEDICO_POSSUI_FOTO: 'Medico Possui Foto',

  // Finalizar Cadastro
  CPF_PACIENTE: 'CPF do Paciente',
  NOME_PACIENTE: 'Nome do Paciente',
  DATA_NASCIMENTO_PACIENTE: 'Data de Nascimento do Paciente',
  GENERO_PACIENTE: 'Genero do Paciente',
  EMAIL_PACIENTE: 'Email do Paciente',
  CELULAR_PACIENTE: 'Celular do Paciente',

  // Acessar Sala Espera
  LINK_EMAIL: 'Acesso pelo link email',
  LINK_AGENDAMENTO: 'Acesso pelo link aplicacao',
  DATA_ACESSO: 'Data do acesso',
  DATA_CONSULTA: 'Data da consulta',
  CONSULTA_EXPIRADA: 'Consulta expirada',
  SALA_DISPONIVEL: 'Sala liberada',

  // Cancelar Agendamento
  DATA_CANCELAMENTO: 'Data do Cancelamento',

  // Acessar Teleconsulta
  MEDICO_NA_SALA: 'medico ja estava na sala',

  // Listar Horários
  UNIDADES_CODIGO: 'Codigos das Unidades Busca de Horarios',
  UNIDADES_NOME: 'Nomes das Unidades Busca de Horarios',
  DATA_HORA_INICIO_BUSCA: 'Data Hora Inicio da Busca',
  DATA_HORA_FIM_BUSCA: 'Data Hora Fim da Busca',
  QUANTIDADE_OFERTAS_AGENDA: 'Quantidade de Ofertas de Agenda',
  DATA_HORA_PROXIMA_AGENDA: 'Data Hora da Proxima Agenda Retornada',
  DIAS_PARA_PROXIMA_AGENDA: 'Distancia em Dias para Proxima Agenda',
  TEMPO_BUSCA: 'Tempo de Busca',
  PRIMEIRA_LISTAGEM_HORARIOS: 'Primeira Listagem de Horario',
  ULTIMA_LISTAGEM_HORARIO: 'Ultima Listagem de Horario',
  TOTAL_LISTAGEM_HORARIO: 'Total de Listagens de Horario',
  PRIMEIRA_OFERTA: 'Primeira Oferta',
  PRIMEIRA_DATA_LISTAGEM: 'Primeira Data Na Agenda Apresentada',
  PRIMEIRO_LOCAL_LISTAGEM: 'Primeiro Local Na Agenda Apresentada',
  PRIMEIRA_AGENDA_ENCAIXE: 'Primeira Agenda Encaixe',
  DIAS_DECORRIDO_PRIMEIRA_AGENDA: 'Tempo Dias Para Primeira Agenda',
  MOTIVO_ALERT: 'Motivo do alert',

  // Verificar Cobertura
  CODIGOS_UNIDADES_COM_COBERTURA: 'Codigos de Unidades com Cobertura',
  NOMES_UNIDADES_COM_COBERTURA: 'Nomes das Unidades com Cobertura',
  CODIGOS_UNIDADES_SEM_COBERTURA: 'Codigos de Unidades sem Cobertura',
  NOMES_UNIDADES_SEM_COBERTURA: 'Nomes das Unidades sem Cobertura',
  POSSUI_COBERTURA: 'Possui Cobertura',

  // Verificar Atendimento
  POSSUI_ATENDIMENTO: 'Possui Atendimento',

  // Exibir Erros
  TIPO_ERRO: 'Tipo de Erro Generico',
  MENSAGEM_ERRO: 'Mensagem de Erro Generica',
  PAGINA_ORIGEM_ERRO: 'Pagina de Origem Erro Generico',
  ENDERECO_REQUISICAO: 'Endereco da Requisicao',

  // Armazenar Erros Genéricos
  CODIGO_ERRO_BACKEND: 'Codigo do Erro do Backend',
  MENSAGEM_ERRO_BACKEND: 'Mensagem do Erro do Backend',
  METODO_REQUISICAO: 'Metodo da Requisicao',
  STATUS_ERRO_RETORNO_REQUISICAO: 'Status do Erro Generico',
  STATUS_TEXTO_ERRO_RETORNO_REQUISICAO: 'Status Texto do Erro Generico',
  PARAMENTROS_REQUISICAO: 'Parametros da Requisicao',

  // Geolocalizacao
  GEO_LOCALIZACAO_FOI_PERMITIDA: 'Clique no botao permitir',
  GEO_LOCALIZACAO_FOI_BLOQUEADA: 'Clique no botao negar',

  // Modal Geolocalizacao
  GEO_LOCALIZACAO_MODAL_FOI_EXIBIDA: 'Visualizacao da modal de instrucoes',

  // TOGGLE_GEO_LOCALIZACAO
  TOGGLE_GEO_LOCALIZACAO_FOI_DESATIVADO: 'Clique no toggle',
  TOGGLE_GEO_LOCALIZACAO_FOI_ATIVADO: 'Clique no toggle',

  // Clicou na sugestao de local
  CLICOU_NA_SUGESTAO_DE_LOCAL: 'Clique no item sugerido',

  // Adicionar à Agenda
  SERVICO_SELECIONADO: 'Servico de Agenda Selecionado',

  // Marcar e Desmarcar filtro
  ACAO: 'Acao',

  // Filtro de Unidades
  ALERT: ' Alert',
  FALLBACK: ' Fallback',

  // Prosseguir Filtro de Unidades
  NOME_UNIDADES_FILTRO: 'Nome das Unidades',
  NOME_UNIDADES_FILTRO_SELECIONADAS: 'Nome das Unidades Selecionadas',
  CODIGOS_RDSL: 'Codigos RDSL',

  // CTA
  ETAPA: 'Etapa',
  FUNCIONALIDADE: 'Funcionalidade',
  ROTULO: 'Rotulo',
  TIPO: 'Tipo',
  DESC: 'Descrição',

  // CANCELAR CONSULTA
  PROXIMA_CONSULTA: 'Próxima consulta',

  // LOGADO
  LOGADO: 'Usuario logado',
  USUARIO: 'Cpf usuario logado',

  // Slots de Encaixe
  ENCAIXE: 'Encaixe',

  // CARD ESPECIALDADES
  TIPO_DE_DADO: 'Tipo de dado',

  // Telas de fallback
  ROTULO_FALLBACK: 'Rotulo do Fallback',
  ORIGEM_FALLBACK: 'Origem Fallback',
  TIPO_FALLBACK: 'Tipo de fallback',

  // A/B Test
  TEST_VARIANT: 'Variante do teste'
}

export default MixPanelPropriedades
