import { DISPARA_EXCECAO } from '../excecao/types'
import { Excecao, ExcecaoAction } from './types'

const initialState: Excecao = {
  codigo: -1,
  mensagem: '',
  fallback: ''
}

export function excecaoReducer(
  state = initialState,
  action: ExcecaoAction
): Excecao {
  switch (action.type) {
    case DISPARA_EXCECAO:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}
