import * as Sentry from '@sentry/react'

const sentry: Pick<
  Sentry.BrowserOptions,
  'dsn' | 'enabled' | 'environment' | 'release'
> = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enabled: process.env.REACT_APP_NODE_ENV === 'production',
  environment: process.env.REACT_APP_NODE_ENV,
  release: process.env.REACT_APP_VERSION
}

export const env = {
  sentry
}
