export const START_CONSULTA = 'START_CONSULTA'
export const LIMPA_CONSULTA = 'LIMPA_CONSULTA'
export const REINICIA_CONSULTA = 'REINICIA_CONSULTA'
export const REINICIA_CONSULTA_MESMO_PACIENTE =
  'REINICIA_CONSULTA_MESMO_PACIENTE'
export const REINICIA_CONSULTA_MESMO_MEDICO_CANCELAMENTO =
  'REINICIA_CONSULTA_MESMO_MEDICO_CANCELAMENTO'
export const ATUALIZA_ESCOLHA = 'ATUALIZA_ESCOLHA'
export const ATUALIZA_MODALIDADE_ATENDIMENTO = 'ATUALIZA_MODALIDADE_ATENDIMENTO'
export const ATUALIZA_CODIGO_REQUISICAO = 'ATUALIZA_CODIGO_REQUISICAO'
export const ATUALIZA_CONFIRMACAO = 'ATUALIZA_CONFIRMACAO'
export const ATUALIZA_ORIGEM_TELECONSULTA = 'ATUALIZA_ORIGEM_TELECONSULTA'
export const ATUALIZA_LOCAL_SUGESTOES = 'ATUALIZA_LOCAL_SUGESTOES'
export const LIMPA_LOCAL_SUGESTOES = 'LIMPA_LOCAL_SUGESTOES'
export const ATUALIZA_FALLBACK_PERMITIDO = 'ATUALIZA_FALLBACK_PERMITIDO'
export const LIMPA_FALLBACK_PERMITIDO = 'LIMPA_FALLBACK_PERMITIDO'
export const DEFINE_METADATA = 'DEFINE_METADATA'
export const JA_LISTOU_HORARIO = 'JA_LISTOU_HORARIO'
export const POSSUI_HORARIOS = 'POSSUI_HORARIOS'
export const IS_LOADING = 'IS_LOADING'
export const HAS_CONFIRMED_APPOINTMENT_DATA = 'HAS_CONFIRMED_APPOINTMENT_DATA'
export const HAS_EXPANDED_SUGGESTION = 'HAS_EXPANDED_SUGGESTION'
export const SET_EXPANSION_SUGGESTION = 'SET_EXPANSION_SUGGESTION'
export const UPDATE_TOKEN = 'UPDATE_TOKEN'
export const SALVAR_VALIDACAO = 'SALVAR_VALIDACAO'
export const LIMPAR_VALIDACAO = 'SALVAR_VALIDACAO'
